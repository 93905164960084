@font-face {
  font-family: "Cera Pro";
  src: url("../../assets/fonts/CeraPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cera Pro";
  src: url("../../assets/fonts/CeraPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cera Pro";
  src: url("../../assets/fonts/CeraPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cera Pro";
  src: url("../../assets/fonts/CeraPro-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
