@import "src/shared/styles/mixin";

.contact-container {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 1%,
    rgb(35, 37, 41) 30%,
    rgb(34, 37, 41) 100%
  );
  padding: 0 13% 5%;

  @include phone() {
    padding: 10% 0 50%;
  }

  @include tablet() {
    padding-bottom: 25%;
    padding-top: 16%;
  }

  &.withoutTitle-container {
    background: transparent;
    padding: 0.6% 27% 5%;

    @include phone() {
      padding: 6.2% 0 8vh;
    }

    @include tablet() {
      padding: 0.6% 15% 8vh;
    }
  }

  h2 {
    text-transform: uppercase;
    text-align: right;
    white-space: pre-wrap;
    padding-top: 11%;
    margin-bottom: 9%;
    font-size: 7.4vw;
    line-height: 7vw;
    margin-top: 0;
    font-weight: 900;

    @include mdDesktop() {
      font-size: 7.1vw;
    }

    @include phone() {
      text-align: center;
      font-size: 7.1vw;
      line-height: 12vw;
      margin-bottom: 9%;
      padding-left: 3%;
      padding-right: 3%;
      @include xs() {
        font-size: 6.6vw;
      }
      @include sm() {
        font-size: 6.6vw;
      }
      @include galaxyFold() {
        font-size: 6.3vw;
      }
    }

    @include tablet() {
      font-size: 13vw;
      line-height: 14vw;
      margin-bottom: 20%;
      text-align: center;
    }

    @include xl() {
      margin-bottom: 9%;
    }

    .part1 {
      font-size: 1em;
      line-height: 1.5em;
      letter-spacing: 1rem;

      @include phone() {
        font-size: 2.525em;
        letter-spacing: 0.5rem;
        @include xs() {
          font-size: 2.2em;
        }
      }

      @include tablet() {
        font-size: 13vw;
        letter-spacing: 0.5rem;
      }
    }

    .part2 {
      font-size: 1.83em;
      letter-spacing: 0.9375rem;
      @include phone() {
        font-size: 2.19em;
        @include xs() {
          font-size: 2.3em;
          letter-spacing: 0.5rem;
        }
      }
      @include tablet() {
        font-size: 13vw;
        letter-spacing: 0.5625rem;
      }
    }
  }

  .form-container {
    width: 62%;
    margin-left: 19%;

    &.light-theme {
      width: 100%;
      margin-left: 0;
      input,
      textarea {
        border-color: white;
      }

      .submit {
        @include tablet() {
          margin-top: 9%;
          margin-bottom: 4%;
          min-width: 392px;
          min-height: 76px;
          font-size: 27px;
        }

        @include phone() {
          min-width: 275px;
          max-width: 275px;
          border-radius: 18px;
          font-size: 22px;
        }
      }

      .invalid-input {
        border-color: #ed6a5a !important;

        &::placeholder {
          color: rgba(255, 255, 255, 0.5) !important;
        }
      }
    }

    @include phone() {
      width: 96%;
      margin-left: 2%;
    }

    @include tablet() {
      width: 96%;
      margin-left: 2%;
    }

    @include xl() {
      width: 60%;
      margin-left: 20%;
    }

    .submit {
      margin-top: 9%;
      margin-bottom: 4%;
      min-width: 290px;

      @include phone() {
        margin-top: 13%;
        margin-bottom: 4%;
        font-size: 5vw;
        width: 80vw;
        max-width: 320px;
        min-width: unset;

        @include sm() {
          font-size: 4vw;
        }
      }

      @include xTablet() {
        margin-top: 9%;
        margin-bottom: 4%;
        border-radius: 11px;
        min-width: 75% !important;
        font-size: 37px;
      }
    }

    .invalid-input {
      border-color: #cc5d5d !important;

      &::placeholder {
        color: #cc5d5d !important;
      }
    }
  }
}
