@import "../../shared/styles/mixin";

.caseLink-container {
  margin-bottom: 2%;
  text-decoration: none !important;

  h2,
  p {
    color: var(--color);
  }

  .caseStudyItem-container {
    height: 311px;
    background: radial-gradient(
      115.57% 115.57% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%,
      rgba(255, 255, 255, 0) 100%
    );
    border-radius: 15px;
    cursor: pointer;
    width: 96%;
    overflow: hidden;

    @include phone() {
      height: 250px;

      @include sm() {
        height: 260px;
      }
    }

    @include tablet() {
      height: 300px;
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 15px;
      padding: 1.5px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.6) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    h2 {
      font-weight: 600;
      font-size: 24px;

      @include phone() {
        font-size: 18px;
      }

      @include tablet() {
        font-size: 22px;
      }

      @include xl() {
        font-size: 28px;
      }
    }

    p {
      font-size: 16px;
      font-weight: 300;

      @include phone() {
        font-size: 12px;
      }

      @include tablet() {
        font-size: 14px;
      }

      @include xl() {
        font-size: 18px;
      }
    }

    img {
      height: 80%;
      object-fit: contain;
    }

    button {
      background: radial-gradient(
        115.57% 115.57% at 50% 100%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0) 100%,
        rgba(255, 255, 255, 0) 100%
      );
      border-radius: 7px;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      color: #ffffff;
      min-height: 33px;
      padding: 0 12px;
      position: relative;
      width: fit-content;
      @include phone() {
        font-size: 12px;
      }

      @include tablet() {
        font-size: 14px;
      }

      @include xl() {
        font-size: 18px;
      }

      &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 7px;
        padding: 1px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.6) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        -webkit-mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    }
  }



  .text-content {
    min-height: 186px;
  }
}
