.skeleton-container {
  background: rgba(226, 229, 231, 0.7);
  animation: fadeInOut 4s infinite;

  @keyframes fadeInOut {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.5;
    }
  }
}
