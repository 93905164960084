@import "src/shared/styles/mixin";

.streamlinedData-container {
  padding-top: 1%;
  background: white;
  color: var(--dark-text);

  .pc1-container {
    @include tablet() {
      margin-top: 20%;
      flex-direction: column-reverse;
    }
    @include xTablet() {
      margin-top: 29%;
    }
    @include phone() {
      margin-top: 2%;
      flex-direction: column-reverse;
    }
    p {
      font-size: 18px;
      max-width: 44%;
      letter-spacing: 1.6px;
      padding: 0;
      margin-left: -1.8%;
      line-height: 30px;
      margin-top: 4.5%;
      font-weight: 300;

      @include xl() {
        font-size: 23px;
        line-height: 37px;
        margin-top: 1.5%;
      }

      @include tablet() {
        font-size: 3.5vw;
        max-width: 79%;
        letter-spacing: 1.6px;
        padding: 0;
        margin-left: 14%;
        line-height: 5.2vw;
        margin-top: 3%;
      }

      @include xTablet() {
        margin-top: 7%;
      }

      @include phone() {
        font-size: 4.4vw;
        max-width: 71%;
        letter-spacing: 1.6px;
        padding: 0;
        margin-left: 14%;
        line-height: 9.2vw;
        margin-top: 0;
      }
    }

    img {
      @include phone() {
        margin-top: -8%;
      }
    }
  }

  .pc2-container {
    @include tablet() {
      margin-top: -10%;
      flex-direction: column;
    }

    @include phone() {
      overflow-x: hidden;
    }

    p {
      font-size: 18px;
      letter-spacing: 1.6px;
      line-height: 30px;
      margin-top: 9%;
      padding: 0 7.2% 0 8.5%;
      position: relative;
      font-weight: 300;

      @include tablet() {
        font-size: 3.6vw;
        max-width: 80%;
        letter-spacing: 1.6px;
        padding: 0;
        margin-left: 11%;
        line-height: 5.3vw;
        margin-top: 3%;
      }

      @include xTablet() {
        max-width: 78%;
        margin-left: 12%;
      }

      @include phone() {
        font-size: 4.2vw;
        max-width: 68%;
        letter-spacing: 1.6px;
        padding: 0;
        margin-left: 14%;
        line-height: 9.4vw;
        font-weight: 300;
        margin-top: 5%;
      }

      @include xl() {
        font-size: 23px;
        line-height: 36px;
        margin-top: 6%;
      }
    }

    img {
      transform: scale(1.7);
      position: relative;
      right: 10%;
      top: 17vh;
      height: 100%;

      @include tablet() {
        transform: unset;
        right: 0;
        top: -11vh;
      }

      @include phone() {
        transform: scale(1.1);
        right: 0;
        top: -10vh;
      }
    }
  }

  .note {
    line-height: 29px;
    margin-top: 17.8%;
    margin-left: 19.5%;
    font-weight: 300;
    text-align: center;
    font-size: 19px;
    width: 60%;
    white-space: pre-line;
    letter-spacing: 1.5px;
    margin-bottom: 3%;

    @include tablet() {
      font-size: 3.6vw;
      max-width: 79%;
      width: 100%;
      letter-spacing: 1.6px;
      padding: 0;
      margin-left: 11%;
      line-height: 5.3vw;
      margin-top: -26%;
      font-weight: 300;
      text-align: left;
    }

    @include phone() {
      line-height: 9.4vw;
      margin-top: -13.2%;
      margin-left: 17.5%;
      text-align: left;
      font-size: 4.3vw;
      width: 68%;
    }

    @include xl() {
      line-height: 37px;
      margin-top: 18%;
      font-weight: 300;
      text-align: center;
      font-size: 24px;
    }
  }

  .custom-letsTalk {
    @include phone() {
      font-size: 33px;
      margin-top: 15%;
      padding: 0 9%;
    }
    @include tablet() {
      margin-right: 8%;
      text-align: right;
      margin-top: 12%;
      font-size: 48px;
    }

    @include xTablet() {
      font-size: 61px;
    }

    @include xl() {
      font-size: 42px;
    }
  }
}
