@import "src/shared/styles/mixin";

.thingsWeDo-container {
  margin-left: 12%;
  width: 75.5%;
  padding-bottom: 90%;

  @include tablet() {
    margin-left: 1%;
    width: 98%;
    padding-bottom: 120%;
  }

  @include phone() {
    margin-left: 3%;
    width: 94.5%;
    padding-bottom: 160%;
  }

  h2 {
    text-align: center;
    font-size: 109px;
    text-transform: uppercase;
    margin-top: 12%;
    margin-bottom: 4%;
    letter-spacing: 6px;
    font-weight: 900;

    @include xl() {
      margin-top: 10%;
    }

    @include phone() {
      font-size: 8vw;
      margin-bottom: 9%;
      letter-spacing: 6px;
    }

    @include tablet() {
      font-size: 10vw;
      margin-bottom: 12%;
      margin-top: 16%;
    }
  }
}
