@import "src/shared/styles/mixin";

.weDesignForBrands-container {
  padding-top: 54vh;
  margin-left: 20.6%;
  width: 59%;

  @include xl() {
    padding-top: 25vh;
  }

  @include tablet() {
    padding-top: 42vh;
    margin-left: 11%;
    width: 80%;
  }

  @include phone() {
    padding-top: 19vh;
    margin-left: 8%;
    width: 86%;
    padding-bottom: 26%;
  }

  h2 {
    font-size: 134px;
    white-space: pre-wrap;
    margin-bottom: 6%;
    text-transform: uppercase;
    font-weight: 900;

    @include xl() {
      font-size: 130px;
    }

    @include phone() {
      font-size: 13.6vw;
      white-space: pre-wrap;
      margin-bottom: 6%;
      text-transform: uppercase;
    }

    @include tablet() {
      font-size: 12vw;
    }

    .blue-text {
      color: var(--primary);
    }
  }

  p {
    font-size: 26px;
    white-space: pre-line;
    line-height: 30px;
    font-weight: 300;
    word-spacing: 4px;
    padding-right: 1%;

    @include xl() {
      line-height: 32px;
    }

    @include tablet() {
      font-size: 4.9vw;
      line-height: 55px;
      margin-top: 12%;
    }

    @include phone() {
      font-size: 4.6vw;
      white-space: pre-line;
      line-height: 43px;
      font-weight: 300;
      word-spacing: 6px;
      padding-right: 1.2%;
    }
  }
}
