@import "../../styles/mixin";

.caseStudy-container {
  position: relative;
  margin-top: -7.8125rem;
  overflow: hidden;
  padding-bottom: 15vh;
  z-index: 4;

  @include phone() {
    padding-bottom: 12vh;
  }

  @include xl() {
    margin-top: -10rem;
  }

  h2 {
    font-size: 12.1875rem;
    text-align: center;
    white-space: nowrap;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 10%;
    letter-spacing: 1.1875rem;
    margin-bottom: 0;

    @include tablet() {
      font-size: 13vw;
    }

    @include phone() {
      font-size: 3.2625rem;
      letter-spacing: 0.4375rem;
      margin-top: 0;
    }

    .case {
      position: relative;
      z-index: 2;
    }

    @include xl() {
      font-size: 16.25rem;
      letter-spacing: 2rem;
      margin-top: 11%;
    }
  }

  .robot-background {
    position: absolute;
    top: 5.125rem;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  .carousel-container {
    margin-top: -6%;

    .carousel-item {
      max-width: 59vw;
      min-width: 59vw;

      @include phone() {
        max-width: 94vw;
        min-width: 90vw;
      }

      @include tablet() {
        max-width: 94vw;
        min-width: 90vw;
      }

      &.only-one {
        @include desktop() {
          margin-left: 20vw;
        }
      }
    }
  }

  .viewAll {
    color: #444444;
    position: absolute;
    bottom: 1.6875rem;
    font-weight: 300;
    text-decoration: none;
    font-size: 1.5rem;
    right: 9.5%;
    letter-spacing: 0.0625rem;
    z-index: 4;
    @include xl() {
      bottom: 3.4375rem;
      font-size: 1.875rem;
      right: 9%;
      letter-spacing: 0.25rem;
    }
  }
}
