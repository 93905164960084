@import "../../shared/styles/mixin";

.customProcess-container {
  position: relative;
  margin-top: -0.625rem;
  padding: 16% 8% 0;

  @include phone() {
    padding: 15% 5% 0;
  }

  @include tablet() {
    padding: 18% 2% 0;
  }

  @include xl() {
    margin-top: -0.125rem;
    padding: 16% 8% 0;
  }

  .mobile-title {
    font-size: 6.125rem;
    letter-spacing: 0.625rem;
    text-transform: uppercase;
    margin-left: 5%;
    margin-bottom: 8%;

    @include phone() {
      margin-left: 0;
      margin-bottom: 7%;
      text-align: center;
      font-size: 17vw;
      font-weight: 900;
      margin-top: 7%;
      line-height: 19vw;
    }
  }

  .border-box {
    height: 79%;
    width: 58.6%;
    position: absolute;
    left: 20.5%;
    top: 27.5%;

    @include phone() {
      height: 79%;
      width: 69%;
      left: 14%;
      top: 21%;
    }

    @include tablet() {
      height: 72%;
      width: 80%;
      left: 10%;
      top: 28.5%;
    }

    @include xTablet() {
      top: 24.5%;
      height: 75%;
    }

    @include xl() {
      top: 32%;
      height: 75%;
      width: 65%;
      left: 17.5%;
    }
    &:before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 1.125rem;
      padding: 3px;
      background: radial-gradient(
        111.62% 232.73% at 103.19% 103.88%,
        #00ffe0 0%,
        #0057ff 100%
      );
      -webkit-mask:
        linear-gradient(#ccc 0 0) content-box,
        linear-gradient(#ccc 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }

  .customProcessTitle-part1 {
    font-size: 6.1875rem;
    font-weight: 900;
    text-align: right;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.5625rem;

    @include xl() {
      font-size: 8.25rem;
      line-height: 10.125rem;
      letter-spacing: 1rem;
    }
  }

  .customProcessTitle-part2 {
    margin: 12% 0 0;
    font-size: 6.1875rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.6875rem;

    @include xl() {
      font-size: 8.25rem;
      line-height: 10.125rem;
      letter-spacing: 1.125rem;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;

    .item {
      @include phone() {
        margin-bottom: 8%;
      }
      @include tablet() {
        margin-bottom: 6%;
      }
    }

    :nth-child(2) {
      @include desktop() {
        margin-bottom: 1%;
      }
    }

    :nth-child(3) {
      @include desktop() {
        margin-top: -6%;
      }
    }
  }
}
