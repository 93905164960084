@import "src/shared/styles/mixin";

.conversionNavigator-container {
  background: white;
  color: var(--light-gray-text);
  padding: 11.2% 16% 0;
  position: relative;
  z-index: 1;

  @include tablet() {
    padding: 38% 12% 0;
  }

  @include phone() {
    padding: 19.2% 8% 0;
  }

  .description {
    margin-top: 0;
    margin-bottom: 6%;
    font-size: 25px;
    font-weight: 300;
    letter-spacing: 0.7px;
    text-align: center;
    word-spacing: 2px;

    @include tablet() {
      font-size: 47px;
      text-align: left;
      line-height: 55px;
    }

    @include phone() {
      font-size: 4vw;
      letter-spacing: 2px;
      text-align: left;
      line-height: 9vw;
    }
  }

  .custom-lets-talk {
    color: var(--light-gray-text);
    @include tablet() {
      margin-top: 0;
      margin-bottom: 6%;
      font-size: 48px;
      font-weight: 300;
      letter-spacing: 0.7px;
      text-align: left;
    }

    @include phone() {
      font-size: 4vw;
      font-weight: 500;
      margin-left: 0.4%;
      margin-top: 11%;
      padding: 0;
      line-height: 34px;
      text-align: right;
    }
  }
}
