@import "src/shared/styles/mixin";
@import "src/shared/styles/animation";

.mobileApps-container {
  animation: fade-in-up 0.4s;

  .mainSection-custom-title {
    margin-left: -3rem !important;
    @include tablet() {
      font-size: 13vw !important;
      margin-left: -2% !important;
      line-height: 185px !important;
      padding-top: 33% !important;
    }

    @include phone() {
      font-size: 12.5vw !important;
      margin-left: -2% !important;
      letter-spacing: 11px !important;
      line-height: 98px !important;
      padding-top: 47% !important;
      white-space: nowrap;
    }
  }

  .mainSection-custom-description {
    @include tablet() {
      font-size: 2.2vw !important;
      width: 95% !important;
      letter-spacing: 3.3px !important;
    }
    @include phone() {
      font-size: 4vw !important;
      width: 80% !important;
      text-align: center !important;
      margin-left: 10%;
      letter-spacing: 2.3px !important;
      margin-top: 5% !important;
    }
  }

  .mainSection-custom-image {
    @include xl() {
      padding-top: 0 !important;
    }
    @include tablet() {
      padding-top: 24.6% !important;
      width: 85% !important;
    }
    @include phone() {
      padding-top: 12.6% !important;
      width: 100% !important;
    }
  }
}
