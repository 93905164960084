@import "src/shared/styles/mixin";

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.relative {
  position: relative;
}

.mobile-branding-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .mobile-item {
    width: 24vw;
    height: auto;
    right: -9%;

    @include lg() {
      width: 27vw;
      right: -12%;
    }

    @include notDesktop() {
      width: 45vw;
      max-width: 340px;
      right: -12%;
    }
  }

  .main-item {
    width: 42vw;
    position: relative;

    @include lg() {
      width: 44vw;
    }

    @include notDesktop() {
      width: 80vw;
      position: unset;
    }
  }
}

.mobile-item {
  width: 15vw;
  position: absolute;
  z-index: 4;
  right: 5%;
  bottom: 10%;
  height: auto;

  @include phone() {
    width: 30vw;
    right: -14%;
    bottom: 8%;
  }

  @include tablet() {
    width: 30vw;
    right: -14%;
    bottom: 8%;
  }
}

.main-item {
  width: 44vw;
  height: auto;
  @include lg() {
    width: 50vw;
  }
  @include phone() {
    width: 100vw;
  }

  @include tablet() {
    width: 100vw;
  }
}

.small-item {
  width: 32vw;
  position: absolute;
  z-index: 4;
  right: 52%;
  bottom: 0;
  height: auto;

  @include phone() {
    width: 58vw;
    right: 34%;
    bottom: 0;
  }

  @include tablet() {
    width: 58vw;
    right: 34%;
    bottom: 0;
  }

  &.mac-item {
    width: 30vw;

    @include phone() {
      width: 58vw;
      right: 40%;
    }

    @include tablet() {
      width: 58vw;
      right: 45%;
    }
  }

  &.branding-item {
    width: 32vw;
    bottom: -6%;

    @include phone() {
      width: 58vw;
      right: 40%;
    }

    @include tablet() {
      width: 58vw;
      right: 40%;
    }
  }
}
