@import "src/shared/styles/mixin";
@import "src/shared/styles/animation";

.insights-container {
  animation: fade-in-up 0.4s;
  .custom-container {
    @include xl() {
      height: 40%;
    }
    @include tablet() {
      height: 63%;
    }
    @include phone() {
      height: 63%;
    }
  }

  .mainSection-container {
    height: fit-content !important;
    padding-top: 20vh;
    min-height: unset !important;

    @include phone() {
      padding-top: 25vh;
    }
  }

  .mainSection-title {
    text-transform: capitalize !important;
    text-align: center;
    font-weight: 900 !important;
    letter-spacing: 13px;
    padding-top: 6%;
    margin-top: 0 !important;
    margin-bottom: 6% !important;
    font-size: 10rem !important;
    line-height: 2.8rem !important;

    @include xl() {
      font-size: 12rem !important;
      padding-top: 4.5%;
    }
    @include phone() {
      font-size: 13vw !important;
      letter-spacing: 2vw !important;
    }
    @include tablet() {
      font-size: 6rem !important;
      letter-spacing: 0.7rem;
      padding-top: 17% !important;
    }
    @include galaxyFold() {
      font-size: 2.75rem !important;
      line-height: 6.75rem !important;
      letter-spacing: 0.5rem !important;
    }
  }

  .mainSection-description {
    font-size: 1.8rem !important;
    margin-bottom: 6% !important;
    margin-top: 0 !important;
    font-weight: 300 !important;
    letter-spacing: 4px !important;
    text-align: center !important;

    @include mdDesktop() {
      font-size: 1.4rem !important;
    }

    @include phone() {
      font-size: 1rem !important;
      margin-top: -2px !important;
      margin-bottom: 30% !important;
    }

    @include xl() {
      font-size: 2.5rem !important;
      margin-bottom: 0 !important;
    }

    @include tablet() {
      font-size: 1.8rem !important;
      font-weight: 500 !important;
      margin: 0 0 6% !important;
    }
  }

  .insight-content {
    width: 50vw;
    margin-left: 25vw;
    z-index: 6;
    padding-bottom: 90%;
    position: relative;

    @include tablet() {
      width: 94vw;
      margin-left: 3vw;
      padding-bottom: 130%;
    }

    @include phone() {
      width: 84vw;
      margin-left: 8vw;
      margin-bottom: 200%;
    }
  }

  .custom-robot-background {
    margin-top: 12%;

    .custom-robot-image {
      z-index: 1;
    }

    .custom-overlay {
      background: rgb(0, 0, 0);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 1%,
        rgba(0, 144, 250, 0) 70%,
        rgb(35, 37, 41) 88%,
        rgb(34, 37, 41) 100%
      );
    }
  }

  #case-study {
    @include phone() {
      margin-top: 0 !important;
    }
  }
}
