@import "src/shared/styles/mixin";

.insightCarousel-container {
  left: 48px;
  position: relative;
  margin-top: 4%;

  @include desktop() {
    width: 90.5%;
  }

  @include phone() {
    left: 0;
  }

  @include tablet() {
    left: 0;
  }

  @include xl() {
    width: 94%;
    left: 50px;
  }
}
