@import "src/shared/styles/variables";
@import "src/shared/styles/canguru.bootstrap";
@import "src/shared/styles/custom-spacing";
@import "src/shared/styles/fonts";
@import "src/shared/styles/form";
@import "src/shared/styles/animation";

html {
  font-size: 16px;

  @include s8 {
    font-size: 14px;
  }
}
body {
  background: var(--background);
  color: var(--color);
  scroll-behavior: smooth;
  margin: 0;
  font-family:
    "Cera Pro",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-tap-highlight-color: transparent;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
button,
a {
  font-family:
    "Cera Pro",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
}

button {
  outline: none;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.text-center {
  text-align: center;
}

.relative {
  position: relative;
}

::selection {
  color: white;
  background: var(--primary);
}

.animated-link {
  &:after {
    content: "";
    display: block;
    width: 0;
    height: 3px;
    background: white;
    transition: width 0.3s;
  }
  &:hover::after {
    width: 100%;
  }
}

.gradient-background {
  background: var(--primary);
  background: radial-gradient(
    111.62% 232.73% at 103.19% 103.88%,
    #00ffe0 0%,
    #0057ff 100%
  );
}

.robot-background {
  .robot-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
    object-position: top;
    object-fit: cover;
    z-index: -1;
  }

  .overlay {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 1%,
      rgb(34, 37, 41) 8%,
      rgba(0, 144, 250, 0) 20%,
      rgba(0, 144, 250, 0) 70%,
      rgb(35, 37, 41) 88%,
      rgb(34, 37, 41) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 71%;
    z-index: 1;

    @include tablet() {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 1%,
        rgb(34, 37, 41) 7%,
        rgba(0, 144, 250, 0) 20%,
        rgba(0, 144, 250, 0) 70%,
        rgb(35, 37, 41) 88%,
        rgb(34, 37, 41) 100%
      );
    }
  }

  .robot-content {
    z-index: 2;
  }
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.letsTalk {
  font-size: 33px;
  font-weight: 300;
  margin-left: 0.4%;
  margin-top: 1%;

  @include phone() {
    font-size: 32px;
    margin-left: 0.4%;
    margin-top: 18%;
    padding: 0 12%;
    line-height: 43px;
  }

  @include tablet() {
    font-size: 61px;
    margin-left: 0.4%;
    margin-top: 1%;
  }

  a {
    text-decoration: none;
    color: var(--secondary);
  }
}

.overflow-hidden {
  overflow: hidden;
}

.hidden {
  display: none !important;
}

.main-content {
  position: relative;
  z-index: 3;
}

.dummy-background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  bottom: 0;
  object-fit: contain;
  z-index: 999999;
  opacity: 0.4;
  pointer-events: none;
}

.case-bottom-container {
  margin-top: -80%;
  padding-bottom: 90%;
  @include tablet() {
    margin-top: -120%;
    padding-bottom: 10%;
  }
  @include phone() {
    margin-top: -120%;
    padding-bottom: 10%;
  }
}

.infinite-scroll-component__outerdiv {
  width: 100% !important;
}

.caseStudy-padding {
  padding: 0 5%;

  @include xl() {
    padding: 0 10%;
  }

  @include phone() {
    padding: 0 6%;
  }

  @include tablet() {
    padding: 0 6%;
  }
}
