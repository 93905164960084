@import "../../../shared/styles/mixin";

header {
  .navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 6fr;
    padding: 1.6% 9%;
    min-height: 80px;
    align-items: center;
    z-index: 99999;
    transition: all 0.4s ease;

    @include tablet() {
      padding: 1.4% 7%;
      min-height: 180px;
    }

    @include xTablet() {
      padding: 6.4% 5% 6.4% 8%;
    }

    @include phone() {
      min-height: 150px;
      padding: 5.4% 8%;
      margin-top: 7%;
      @include mdHeight() {
        margin-top: 2%;
        min-height: 60px;
      }
      @include smHeight() {
        margin-top: 0;
        min-height: 50px;
      }
    }

    @include xl() {
      min-height: 166px;
    }

    .logo {
      width: 100%;
      max-width: 200px;

      @include tablet() {
        width: 30vw;
      }

      @include xTablet() {
        width: 28vw;
        height: 101px;
        max-width: unset;
      }

      @include phone() {
        width: 30vw;
      }
    }

    .drawer-btn {
      justify-self: end;
      background: transparent;
      padding: 0;
      z-index: 2;

      &.light-drawer img {
        filter: invert(0);
      }

      &.dark-drawer img {
        filter: invert(1);
      }

      @include xTablet() {
        width: 50px;
        height: 50px;
      }
    }

    .list-container {
      display: flex;
      justify-self: end;
      width: 50%;
      justify-content: space-between;
      padding: 0 1% 0 0;
      height: 100%;

      @include xl() {
        width: 56%;
      }

      .list-item {
        position: relative;
        text-decoration: none;
        list-style: none;
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0 2%;
        background: rgba(0, 0, 0, 0);
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        transition: all 0.6s ease;

        &:before {
          transition: all 0.4s ease;
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: rgb(68, 68, 68);
          background: linear-gradient(
            61deg,
            rgb(255 255 255 / 30%) 0%,
            rgb(255 255 255 / 9%) 100%
          );
          backdrop-filter: blur(10px);
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          z-index: 0;
          opacity: 0;
        }

        a,
        button {
          background: transparent;
          outline: none;
          color: white;
          text-decoration: none;
          transition: all 0.2s ease;
          font-size: 16px;
          font-weight: 300;
          z-index: 2;
          text-transform: capitalize;
          @include xl() {
            font-size: 22px;
          }
        }

        .navbar-sub-container {
          position: absolute;
          top: 99.6%;
          left: 0;
          width: 200px;
          padding: 12px;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
          border-top-right-radius: 12px;
          opacity: 0;
          background: rgb(255, 255, 255);
          background: linear-gradient(
            100deg,
            rgba(255, 255, 255, 0.15) 0%,
            rgba(255, 255, 255, 0.05) 100%
          );
          backdrop-filter: blur(10px);
          display: grid;
          transition: all 0.4s ease;
          pointer-events: none;

          &:before {
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            border-top-right-radius: 12px;
            transition: all 0.4s ease;
          }

          &.fade-in {
            animation: fade-in-down 0.4s forwards;
          }

          &.fade-out {
            animation: fade-out-up 0.4s forwards;
          }

          a {
            background: transparent;
            outline: none;
            font-size: 14px;
            margin-bottom: 7%;
            cursor: pointer;
            z-index: 2;

            &:hover {
              color: #00639f;
            }
          }
        }

        @include hover {
          .link-item {
            color: #00639f;
            filter: drop-shadow(0px 0px 6px rgba(255, 255, 255, 0.5));
          }
        }

        &.active {
          .navbar-sub-container {
            pointer-events: unset;
          }

          &:before {
            opacity: 1;
          }
        }
      }

      @keyframes fade-in-down {
        from {
          opacity: 0;
          transform: translateY(-5%);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }

      @keyframes fade-out-up {
        from {
          opacity: 1;
          transform: translateY(0);
        }
        to {
          opacity: 0;
          transform: translateY(-5%);
        }
      }
    }

    @keyframes fade-out-bottom {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    &.on-scrolled {
      background: #2c2c2c70;
      height: 95px;
      top: -1%;

      @include xl() {
        min-height: 90px;
        height: 120px;
      }

      @include tablet() {
        top: 0;
        min-height: 110px;
      }

      @include phone() {
        top: -1.6%;
        height: 110px;
        min-height: 110px;
      }
    }

    &.on-scrolled:before,
    .navbar-sub-container:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      backdrop-filter: blur(12px);
    }
  }

  .side-menu {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    display: block;
    overflow: hidden;
    overflow-y: scroll;
    box-sizing: border-box;
    width: 331px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    background: radial-gradient(
      115.57% 115.57% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    border-radius: 30px 0 0 30px;
    filter: drop-shadow(-10px 0px 20px rgba(0, 0, 0, 0.15));
    backdrop-filter: blur(20px);
    z-index: 999999;
    transition: transform 0.2s ease-out;
    transform: translateX(100%);

    .active {
      opacity: 1 !important;
    }

    .side-menu-items-container {
      margin: 12% 7%;
      padding: 0 6%;

      .side-menu-header {
        gap: 12px;
        min-height: 80px;

        button {
          background: transparent;
        }
      }

      .side-menu-item {
        display: block;
        width: 100%;
        margin-top: 10%;
        padding: 3% 0;
        font-weight: bold;
        transition: all 0.4s ease;
        text-decoration: none;
        color: var(--text);
        border-radius: 8px;
        opacity: 1;

        p {
          text-align: left;
          margin-bottom: 0;
          margin-top: 0;
          font-size: 20px;
          font-weight: 500;
        }
      }
    }

    .consultation-button {
      margin-top: 20%;
      box-shadow: 1px 15px 60px -12px black;
    }

    .dropdown-button {
      background: transparent;

      .dropdown-icon {
        position: relative;
        width: 15px;
        height: 15px;

        &:before,
        &:after {
          content: "";
          position: absolute;
          background-color: white;
          transition: transform 0.25s ease-out;
        }

        &:before {
          top: 0;
          left: 50%;
          width: 2px;
          height: 100%;
          margin-left: -0.5px;
        }

        &:after {
          top: 50%;
          left: 0;
          width: 100%;
          height: 2px;
          margin-top: -1px;
        }

        &.open {
          cursor: pointer;

          &:before {
            transform: rotate(90deg);
          }

          &:after {
            transform: rotate(180deg);
          }
        }
      }
    }

    .dropdown-menu {
      padding-bottom: 2%;
      border-bottom: 1px solid white;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.4s ease-out;

      &.show {
        max-height: 800px;
      }
    }

    .socialMedia-buttons {
      margin-top: 4vh;
      gap: 18px;

      button {
        background: transparent;
        color: white;
      }
    }

    .consultation-btn {
      min-width: 100%;
      font-size: 18px;
      min-height: 50px;
      margin-top: 4vh;
    }
  }

  .overlay {
    background: rgba(82, 82, 82, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .side-menu-open {
    .overlay {
      opacity: 1;
      pointer-events: all;
    }

    .side-menu {
      transform: translateX(0);
    }
  }

  &.light-header {
    .navbar {
      a,
      button {
        color: var(--gray-text) !important;

        &:hover {
          color: #00639f !important;
        }
      }

      &.on-scrolled {
        background: rgb(255 255 255 / 79%);
        border-bottom: 1px solid #00000012;
      }

      .navbar-sub-container {
        background: rgb(255 255 255 / 79%);
        background: linear-gradient(
          112deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.5) 100%
        ) !important;
      }
    }

    .side-menu {
      background: radial-gradient(
        115.57% 115.57% at 50% 100%,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
      ) !important;
    }
  }
}
