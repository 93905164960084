@import "src/shared/styles/mixin";
@import "src/shared/styles/animation";

.userExperienceDesign-container {
  animation: fade-in-up 0.4s;

  .custom-label {
    font-size: 130px !important;
    padding-top: 11% !important;
    margin-left: -1.1875rem !important;
    line-height: 10.3125rem !important;

    @include xl() {
      padding-top: 12% !important;
      font-size: 9.43vw !important;
      line-height: 11.3125rem !important;
    }

    @include tablet() {
      font-size: 8.8vw !important;
      padding-top: 42% !important;
      line-height: 121px !important;
      text-align: center;
      margin-left: 0;
      letter-spacing: 10px !important;
    }

    @include phone() {
      font-size: 7.9vw !important;
      padding-top: 45% !important;
      line-height: 125px !important;
      margin-left: 0 !important;
      text-align: center !important;
      letter-spacing: 9px !important;
      white-space: nowrap;
    }
  }

  .custom-description {
    font-size: 24px !important;
    width: 83% !important;

    @include tablet() {
      font-size: 4.8vw !important;
      width: 96% !important;
    }

    @include phone() {
      font-size: 4vw !important;
      width: 100% !important;
      text-align: center !important;
    }

    @include xl() {
      font-size: 30px !important;
    }
  }

  .custom-image {
    padding-top: 1.6% !important;
    width: 77% !important;
    left: 1.5%;
    position: relative;

    @include tablet() {
      width: 93% !important;
    }

    @include phone() {
      padding-top: 10.6% !important;
      width: 100% !important;
      left: 1.5%;
      position: relative;
    }
  }

  .processItem-container {
    padding: 8.5% 12% 0;

    @include tablet() {
      padding: 4.5% 0 0;
    }

    @include phone() {
      padding: 4.5% 2% 0;
    }
    h2 {
      @include tablet() {
        font-size: 16vw;
        text-transform: uppercase;
        margin-bottom: 1%;
        line-height: 182px;
        margin-left: 0;
      }
    }
    .custom-border {
      height: 82%;
      top: 24.5%;
    }
    .customProcess-item {
      @include tablet() {
        min-height: 27vh;
      }

      @include phone() {
        min-height: 33vh;
      }

      &:nth-child(0) {
        padding: 2% 6% 6% 24.5%;
      }

      &:nth-child(2) {
        padding: 4% 6% 6% 24.5%;
      }

      &:nth-child(3) {
        padding: 2% 6% 6% 24.5%;
      }
    }
  }

  .bottom-container {
    padding-bottom: 125%;

    @include tablet() {
      padding-bottom: 150%;

      @include mdTablet() {
        padding-bottom: 255%;
      }
    }

    @include phone() {
      padding-bottom: 150%;
    }
  }

  .case-study {
    margin-top: -120%;
  }

  .left-title {
    @include tablet() {
      font-size: 19vw;
      letter-spacing: 17px;
      margin-left: 13%;
    }
  }

  .right-title {
    @include tablet() {
      font-size: 19vw;
      letter-spacing: 17px;
    }
  }
}
