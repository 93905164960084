@import "src/shared/styles/mixin";

.brandingItem-container {
  width: 100%;
  border-radius: 15px;
  margin-bottom: 6%;
  min-height: 197px;
  background: radial-gradient(
    117.89% 771.88% at 0% 0%,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(5px);

  @include tablet() {
    margin-bottom: 4%;
    min-height: 362px;
  }

  @include phone() {
    margin-bottom: 11%;
    min-height: 272px;
  }

  p {
    font-size: 38px;
    text-align: center;
    white-space: pre-line;
    font-weight: 500;
    letter-spacing: 4px;

    @include tablet() {
      font-size: 7.5vw;
    }

    @include phone() {
      font-size: 9.7vw;
      padding: 2%;
    }
  }
}
