@import "src/shared/styles/mixin";

.results-container {
  margin-top: 6%;
  margin-bottom: 4%;

  @include notDesktop() {
    margin-bottom: 26% !important;
    .text-container {
      width: 100% !important;
      padding: 0 7% !important;
    }
  }

  .text-container {
    width: 100%;
    margin-top: 6%;
    margin-bottom: 12%;

    h2 {
      font-size: 26px;
      font-weight: bold;
      letter-spacing: 1px;
      color: var(--dark-text);
    }

    p {
      font-size: 22px;
      font-weight: 300;
      letter-spacing: 1.5px;
      color: var(--gray-text);
      white-space: pre-line;
      line-height: 30px;
    }
  }

  .main-image-container {
    position: relative;

    .main-image {
      width: 50vw;

      @include phone() {
        width: 94vw;
      }
    }
  }

  .gray-background {
    background-color: var(--case-study-gray-background);
    height: 40vh;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }

  .main-mobile-list {
    margin-left: 9%;

    @include phone() {
      flex-wrap: wrap;
      justify-content: center;
    }

    .main-image {
      width: 30vw !important;

      @include phone() {
        width: 50vw !important;
      }
    }
  }
}
