@import "src/shared/styles/mixin";
@import "src/shared/styles/animation";

.caseListSection-container {
  animation: fade-in-up 0.4s;

  @include phone() {
    margin-top: 20%;
  }
}
