@import "src/shared/styles/mixin";

.thingsWeCanDo-container {
  .page-content {
    padding-bottom: 76%;

    @include phone() {
      padding-bottom: 110%;
    }

    @include tablet() {
      padding-bottom: 110%;
    }
    .padding {
      padding: 11.5% 17% 4.5%;

      @include tablet() {
        padding: 20.5% 2% 2%;
      }

      @include mdTablet() {
        padding: 20.5% 6% 2%;
      }

      @include phone() {
        padding: 20.5% 20% 1.5%;
      }
      @include galaxyFold() {
        padding: 20.5% 6% 1.5%;
      }
    }

    h2 {
      margin-top: 0;
      font-size: 76px;
      text-transform: uppercase;
      letter-spacing: 7px;
      margin-bottom: 7.5%;
      font-weight: 900;

      @include xl() {
        margin-top: 4%;
        font-size: 94px;
      }

      @include phone() {
        margin-top: 2%;
        font-size: 8vw;
        margin-bottom: 21%;
        text-align: center;
      }

      @include galaxyFold() {
        font-size: 10vw;
      }

      @include tablet() {
        margin-top: 0;
        font-size: 8vw;
        text-transform: uppercase;
        letter-spacing: 7px;
        margin-bottom: 6vh;
        text-align: center;
      }
    }

    .list-container {
      flex-wrap: wrap;
      column-gap: 72px;
      margin-bottom: 9%;

      @include phone() {
        margin-bottom: 28%;
        justify-content: center;
        flex-direction: column;
      }

      @include tablet() {
        margin-bottom: 25%;
        justify-content: center;
        flex-direction: column;
      }

      p {
        font-size: 32px;
        text-transform: uppercase;
        margin-top: 0;
        letter-spacing: 2px;
        font-weight: bold;

        &.ways-item {
          @include xTablet() {
            font-size: 59px;
          }
        }

        @include phone() {
          font-size: 22px;
          letter-spacing: 1.5px;
          text-align: center;
          margin-bottom: 11%;
          white-space: pre;
        }

        @include galaxyFold() {
          font-size: 12px;
        }

        @include xl() {
          font-size: 40px;
        }

        @include tablet() {
          font-size: 42px;
          margin-bottom: 3%;
        }
      }
    }

    .letsTalk-container {
      margin-top: 16.5%;

      @include xl() {
        margin-top: 0;
      }

      @include tablet() {
        margin-top: 8.5%;
      }

      @include phone() {
        padding-bottom: 42%;
      }

      .custom-lets-talk {
        @include phone() {
          margin-top: -18%;
        }
        @include galaxyFold() {
          font-size: 21px;
        }
      }
    }
  }
}
