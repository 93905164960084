@import "src/shared/styles/mixin";

.insightTag-container {
  p {
    font-size: 16px !important;
    font-weight: 300;
    width: 100%;
    border: 1px solid #d9d9d9;
    padding: 8px 15px;
    margin: 0;
    border-radius: 5px;
    text-transform: capitalize;

    @include xl() {
      font-size: 21px;
      color: var(--dark-text);
    }
  }
}
