@import "src/shared/styles/mixin";

.similar-container {
  width: 100%;
  margin-bottom: 70%;
  position: relative;
  z-index: 7;

  @include notDesktop() {
    margin-bottom: 90%;
  }

  .title {
    text-align: center;
    font-size: 34px;
    font-weight: 300;
    letter-spacing: 2px;
    color: var(--dark-text);
  }

  .gap {
    gap: 20px;
  }

  .carousel-item {
    @include desktop() {
      width: 30vw;
    }

    .case-item {
      background: radial-gradient(
          111.62% 232.73% at 103.19% 103.88%,
          #00ffe0 0%,
          #0057ff 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    }
  }

  .carousel-container {
    .slick-track {
      margin: auto;
      @include phone() {
        width: 100% !important;
      }
    }

    .carousel-item {
      max-width: 35vw;
      min-width: 35vw;

      @include phone() {
        max-width: 94vw;
        min-width: 90vw;
      }

      @include tablet() {
        max-width: 94vw;
        min-width: 90vw;
      }
    }
  }
}
