@import "src/shared/styles/mixin";

.insightItem-container {
  position: relative;
  width: 100%;
  border-radius: 20px;
  background: radial-gradient(
    115.57% 115.57% at 50% 100%,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  color: var(--color);
  text-decoration: none;
  padding: 2%;
  margin-top: 6%;
  margin-bottom: 3%;
  box-sizing: border-box;
  transition: background-position 0.4s ease;
  cursor: pointer;

  @include phone() {
    padding: 3% 5%;
  }

  &:hover {
    background-size: 200%;
    background-position-x: -100%;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 30px;
    padding: 1.5px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 20px;
    background: var(--image-background);

    @include phone() {
      height: 350px;
    }

    @include xl() {
      height: 303px;
    }
  }

  h2 {
    font-size: 26px;
    margin-top: 3%;

    @include phone() {
      font-size: 20px;
    }

    @include xl() {
      font-size: 30px;
      margin-top: 5%;
      margin-bottom: 2%;
    }
  }

  p {
    font-size: 14px;
    white-space: pre-wrap;

    @include phone() {
      font-size: 18px;
    }

    @include xl() {
      font-size: 18px;
    }
  }
}
