.tinyImage-container {
  .mobile-item {
    position: absolute;
    z-index: 6;
    bottom: 0;
    left: 37%;
    width: 100%;
    height: 100%;
    transform: scale(0.8);
  }

  .logo-container {
    position: absolute;
    padding: 3px;
    backdrop-filter: blur(20px);
    border-radius: 5px;
    width: 50%;
    height: 80px;
    left: 4%;
    z-index: 6;
    bottom: 4%;
    background-color: rgba(255, 255, 255, 0.5);
    .logo {
      object-fit: contain !important;
      width: 100%;
      height: 100% !important;
      border-radius: 5px;
    }
  }
}
