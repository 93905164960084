@import "src/shared/styles/mixin";

.mainSection-container {
  background: #5fc8e3;
  background: linear-gradient(
    180deg,
    #5fc8e3 0%,
    rgba(255, 255, 255, 0) 80%,
    rgba(255, 255, 255, 0) 100%
  );
  overflow: hidden;
  height: 100vh;

  @include tablet() {
    min-height: 1080px;
  }

  @include phone() {
    min-height: 812px;
  }

  @include phoneSmallHeight() {
    min-height: 1200px !important;
  }

  @include nestHub() {
    min-height: 1300px;
  }

  &.gradient-background {
    background: var(--primary);
    background: radial-gradient(
        100% 100% at 100% 100%,
        #1ff7e5 0%,
        #086bfc 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    background-blend-mode: screen;
    backdrop-filter: blur(30px);

    @include desktop() {
      height: 110vh;
    }

    @include phone() {
      height: 100vh;
      @include smHeight() {
        min-height: 820px;
      }
      @include mdHeight() {
        min-height: 820px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -40px;
      left: 0;
      width: 100%;
      height: 9%;
      background: white;
      mix-blend-mode: screen;
      filter: blur(17px);
      transform: scale(1.1);
    }
  }

  &.transparent-background {
    background: transparent !important;
    @include phone() {
      height: 83vh !important;
    }
  }

  .background-image {
    width: 100%;
    aspect-ratio: 16 / 9;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    object-fit: cover;
    filter: blur(10px);
    object-position: bottom center;
  }

  .bottom-description {
    h1 {
      text-transform: capitalize !important;
      margin-bottom: 0;
      font-size: 11.5625rem;
      font-weight: 900;

      @include phone() {
        font-size: 3.75rem !important;
        letter-spacing: 0.5rem !important;
      }
    }
    p {
      margin-bottom: 18%;
      font-size: 2.25rem;
      font-weight: bold;
      letter-spacing: 0.075rem;
      margin-top: 0.3%;

      @include phone() {
        font-size: 1.25rem;
      }

      @include xl() {
        font-size: 2.9375rem;
        margin-bottom: 18%;
        margin-top: 2.3%;
      }
    }
  }

  .center-container {
    height: 100%;
    position: relative;
    z-index: 2;

    @include xl() {
      height: calc(100% - 6.375rem);
    }

    @include phone() {
      width: 90%;
      height: 94%;
      margin-left: 5%;
    }

    h1 {
      font-size: 5rem;
      font-weight: 900;

      @include phone() {
        text-align: left;
        font-size: 3.125rem;
        margin-top: 20%;
      }

      @include tablet() {
        text-align: center;
        font-size: 4.375rem;
        font-weight: 500;
        margin-top: 0;
      }

      @include xl() {
        font-size: 6.9rem;
        line-height: 9.1875rem;
        margin-top: 14%;
      }

      &.big-title {
        font-size: 12.5rem;
        margin-top: 0;
        text-transform: uppercase;

        @include phone() {
          font-size: 4rem;
          letter-spacing: 0.125rem;
          margin-bottom: 0;
        }

        @include xl() {
          font-size: 14.375rem;
          letter-spacing: 1.4375rem;
        }
      }
    }

    p {
      font-size: 1.5rem;
      width: 42%;
      font-weight: 300;
      text-align: center;
      margin: 2.3% 0 6%;

      @include phone() {
        font-size: 1.4375rem;
        width: 100%;
        font-weight: 300;
        text-align: left;
        margin-bottom: 8%;
        margin-top: 13%;

        @include iphonePlus() {
          margin-top: 8%;
        }

        @include smHeight() {
          font-size: 1rem;
          margin: 0;
        }
      }

      @include tablet() {
        font-size: 1.5625rem;
        width: 78%;
        font-weight: 300;
        text-align: center;
        margin: 8.3% 0 10vw;
      }

      @include xl() {
        font-size: 2rem;
        margin: 2.3% 0 7%;
        width: 46%;
      }
    }

    button {
      @include phone() {
        margin-top: 18%;
      }

      @include xl() {
        min-width: 400px;
        font-size: 30px;
      }
    }
  }

  .image-container {
    &.small-label {
      h1 {
        font-size: 10.875rem;
        text-transform: capitalize;
        margin-left: 8%;
        line-height: 170px;
        padding-top: 5.5%;
        font-weight: 900;

        @include phone() {
          font-size: 4.25rem;
          text-transform: capitalize;
          margin-left: 8%;
          line-height: 5rem;
          padding-top: 40.5%;
          letter-spacing: 0.3125rem;
        }
      }

      p {
        width: 70%;
        letter-spacing: 0.2125rem;

        @include phone() {
          width: 100%;
          letter-spacing: 0.2125rem;
          font-size: 1rem;
          text-align: center;
          margin-top: 8%;
        }
      }
    }

    h1 {
      font-size: 10.875rem;
      text-transform: uppercase;
      margin-bottom: 0;
      margin-left: -0.1875rem;
      letter-spacing: 0.875rem;
      line-height: 12.3125rem;
      margin-top: 0;
      padding-top: 11%;
      font-weight: 900;

      @include xl() {
        font-size: 13.3rem;
        line-height: 15rem;
      }
    }

    p {
      font-size: 1.8125rem;
      text-transform: uppercase;
      margin-top: 0;
      width: 84%;
      text-align: right;
      letter-spacing: 3.3px;

      @include xl() {
        font-size: 2vw;
        width: 87%;
      }
    }

    img {
      padding-top: 3.6%;
      width: 33%;

      @include xl() {
        padding-top: 11%;
      }
    }

    .bottom-container {
      position: absolute;
      bottom: 10%;
      z-index: 2;
      display: grid;

      .freeConsultation {
        box-sizing: border-box;
        background: radial-gradient(
          115.57% 115.57% at 50% 100%,
          rgba(255, 255, 255, 0.5) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
        border-radius: 15px;
        border-top: 2px solid white;
      }

      .arrow-btn {
        background: transparent;
        color: white;
        width: 2.8125rem;
        height: 2.8125rem;
        border-radius: 50%;
        transition: all 0.4s ease;
        justify-self: center;
        margin-top: 12%;

        @include phone() {
          svg {
            width: 1.625rem !important;
            height: 1.625rem !important;
          }
        }

        @include tablet() {
          svg {
            width: 1.625rem !important;
            height: 1.625rem !important;
          }
        }

        @include xl() {
          svg {
            width: 1.3rem;
            height: 1.3rem;
          }
        }
        &:hover {
          background: rgba(68, 68, 68, 0.1);
        }
      }
    }
  }
}
