@import "src/shared/styles/mixin";

.loader-container {
  padding: 10% 25% 0;
  background: white;

  @include phone() {
    padding: 42% 8% 0;
  }
}
