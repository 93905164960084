@import "src/shared/styles/mixin";

.caseStudy-loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(
    111.62% 232.73% at 103.19% 103.88%,
    #00ffe0 0%,
    #0057ff 100%
  );
  transition: background-size 0.5s ease-in-out;

  @include phone() {
    min-height: 90vh;
  }
}

.loader {
  width: 48px;
  height: 48px;
  background: #fff;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.2);
    border-radius: 10%;
  }
  50% {
    transform: rotate(180deg) scale(1.5);
    border-radius: 50%;
  }
  100% {
    transform: rotate(360deg) scale(0.2);
    border-radius: 10%;
  }
}

.full-screen-loader {
  animation: shrink-bg 0.5s forwards;
  animation-delay: 0.5s; // This can be adjusted based on when you want to start shrinking the background
}

@keyframes shrink-bg {
  from {
    height: 100vh;
  }
  to {
    height: 60vh;
  }
}

.white-space {
  height: 50vh;
}
