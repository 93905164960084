@import "src/shared/styles/mixin";

.ourSolution-container {
  margin-top: 6%;
  width: 100%;
  padding: 0 9%;

  @include notDesktop() {
    padding: 0 4%;
  }

  .text-container {
    padding-left: 4%;
    margin-top: 2%;

    @include notDesktop() {
      padding-left: 3%;
      padding-right: 0;
      margin-top: 4%;

      .text-container {
        position: relative;
      }

      .svgTopImage {
        top: 3.5% !important;
      }
    }

    h2 {
      font-size: 26px;
      font-weight: bold;
      letter-spacing: 1px;
      color: var(--dark-text);
    }

    p {
      font-size: 22px;
      font-weight: 300;
      letter-spacing: 1.5px;
      color: var(--gray-text);
    }
  }

  .main-image {
    width: 100%;
    z-index: 3;
  }

  .mobile-main-image {
    width: 35%;
    z-index: 3;
  }

  .svgUnderImage {
    transform: translate(-50%, 0%);
    width: 100%;
    height: auto;
    position: relative;
    z-index: 0;
    margin-top: -90%;

    &.mobile-svg-under-image {
      transform: translate(-59%, 0%);
      margin-top: -30%;
    }

    &.one-mobile-svg-under-image {
      transform: translate(-80%, 0%);
      margin-top: -26%;

      @include notDesktop() {
        transform: translate(-72%, 0%);
      }
    }
  }

  .svgTopImage {
    position: absolute;
    top: 0;
    right: 0;
    height: auto;
    z-index: 2;
    transform: translateX(100%);
  }

  .mobile-text-container {
    padding-left: 0;
    padding-right: 0;
  }

  .one-mobile-image {
    width: 57%;

    @include notDesktop() {
      width: 80%;
    }
  }
}
