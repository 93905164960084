@import "src/shared/styles/mixin";
@import "src/shared/styles/animation";

.animation-container {
  animation: fade-in-up 0.4s;

  .main-section-label {
    padding-top: 12% !important;

    @include xl() {
      font-size: 13.5rem !important;
    }

    @include phone() {
      padding-top: 45% !important;
      font-size: 13vw !important;
      letter-spacing: 2.3vw !important;
    }
  }
  @include xl() {
    .main-section-description {
      margin-top: 1% !important;
    }
  }
  .main-section-image {
    padding-top: 5% !important;
    width: 48% !important;

    @include tablet() {
      padding-top: 11% !important;
      width: 79% !important;
    }

    @include phone() {
      padding-top: 34% !important;
      width: 78% !important;
    }
  }

  @include tablet() {
    .main-section-label {
      font-size: 14.2vw !important;
      margin-left: 0 !important;
      line-height: 150px !important;
      padding-top: 34% !important;
      text-align: center !important;
      letter-spacing: 8px !important;
    }

    .main-section-description {
      width: 100% !important;
      letter-spacing: 3.4px !important;
      text-align: center !important;
      font-size: 4.2vw !important;
    }
    .custom-button {
      font-size: 38px;
      min-width: 53vw;
      min-height: 100px;
    }
  }

  @include phone() {
    .main-section-description {
      font-size: 3.3vw !important;
      margin-top: 6% !important;
    }
    .custom-bottom-container {
      bottom: 14% !important;

      button {
        svg {
          width: 26px !important;
          height: 26px !important;
        }
      }
    }
  }
}
