.paginator-container {
  font-size: 30px;
  width: 100%;
  height: 60px;
  margin-top: 4%;

  button {
    color: var(--disabled);
  }

  .dots {
    margin: 0;
    height: 45px;
    color: var(--disabled);
  }

  button {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    padding: 1%;
    border-radius: 50%;
    transition: background-color 0.4s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    p {
      font-size: 30px;
      font-weight: bold;
      color: var(--disabled);
      padding: 0;
      margin: 0;

      &.active {
        color: var(--color);
      }
    }

    .mat-icon {
      width: 10px;
      height: 10px;
      line-height: 12px;
      justify-content: center;
      display: flex;
      align-items: center;
      fill: var(--disabled);
      font-size: 12px;
    }
  }
}
