@import "mixin";

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 1% !important;
}

.p-2 {
  padding: 2% !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-col-0 {
  padding-left: 0 !important;
  @include phone() {
    padding-right: 0 !important;
  }
}

.p4-col-0 {
  padding-right: 0 !important;
  @include phone() {
    padding-left: 0 !important;
  }
}

.ml-2 {
  margin-left: 4%;
}
