@import "src/shared/styles/mixin";
@import "src/shared/styles/animation";

.industrySpecificSoftware-container {
  animation: fade-in-up 0.4s;

  .welcome-title {
    font-size: 55px !important;
    text-transform: uppercase !important;
    margin-left: 7% !important;
    line-height: 86px !important;
    padding-top: 12.5% !important;
    letter-spacing: 9px !important;

    @include tablet() {
      font-size: 7.5vw !important;
      margin-left: 6% !important;
      line-height: 90px !important;
      padding-top: 34.5% !important;
    }

    @include xTablet() {
      font-size: 7.8vw !important;
      padding-top: 38.5% !important;
    }

    @include phone() {
      font-size: 7.3vw !important;
      line-height: 10vw !important;
      padding-top: 43.5% !important;
      letter-spacing: 5px !important;
    }

    @include xl() {
      font-size: 61px !important;
      @include bgHeight() {
        line-height: 100px !important;
        font-size: 70px !important;
      }
    }

    @include xxl() {
      padding-top: 10% !important;
      font-size: 80px !important;
    }
  }

  .welcome-description {
    width: 86% !important;
    letter-spacing: 3.4px !important;
    font-size: 24px !important;

    @include tablet() {
      font-size: 1.9vw !important;
      width: 93% !important;
      letter-spacing: 3.3px;
    }

    @include phone() {
      width: 100% !important;
      font-size: 2.5vw !important;
      text-align: center;
      margin-top: 6%;
    }

    @include xl() {
      font-size: 26.5px !important;
      @include bgHeight() {
        font-size: 30.9px !important;
      }
    }

    @include xxl() {
      font-size: 32px !important;
    }
  }

  .welcome-image {
    padding-top: 0 !important;
    width: 30% !important;

    @include tablet() {
      width: 72% !important;
    }

    @include xTablet() {
      width: 64% !important;
    }

    @include phone() {
      padding-top: 7% !important;
      width: 88% !important;
      @include xxs() {
        width: 64% !important;
      }
    }

    @include xxl() {
      width: 30% !important;
    }
  }
}
