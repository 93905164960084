@import "src/shared/styles/mixin";

.weDesignForHuman-container {
  .weDesign-border {
    width: 89%;
    margin-left: 10.5%;
    margin-top: 11%;

    @include xl() {
      margin-top: -5%;
    }

    @include tablet() {
      width: 112%;
      margin-left: 1.5%;
      margin-top: 26%;

      @include xTablet() {
        margin-top: 31%;
      }
    }

    @include phone() {
      width: 113%;
      margin-left: 0.5%;
      margin-top: 13%;
    }
  }

  .page-content {
    width: 60%;
    margin-left: 20%;
    margin-top: -22%;

    @include tablet() {
      width: 78%;
      margin-left: 12%;
      margin-top: -30%;
    }

    @include phone() {
      width: 88%;
      margin-left: 6%;
      margin-top: -25%;

      @include xs() {
        width: 90%;
        margin-left: 6%;
      }
    }

    h2 {
      font-size: 8.5vw;
      text-transform: uppercase;
      white-space: pre-line;
      line-height: 148px;
      letter-spacing: 7px;
      margin-bottom: 4%;
      font-weight: 900;

      @include xl() {
        line-height: 190px;

        @include xTablet() {
          font-size: 11.2vw;
          line-height: 147px;
        }
      }

      .blue-text {
        color: var(--primary);
      }

      @include tablet() {
        font-size: 10.7vw;
        line-height: 141px;
        letter-spacing: 6px;
        margin-bottom: 14%;
      }

      @include phone() {
        font-size: 11.3vw;
        text-transform: uppercase;
        white-space: pre-line;
        letter-spacing: 7px;
        margin-bottom: 11%;
        line-height: 15vw;

        @include xs() {
          font-size: 11vw;
        }

        @include galaxyFold() {
          font-size: 10vw;
        }
      }
    }

    .description {
      white-space: pre-line;
      font-size: 1.8vw;
      font-weight: 300;
      padding-left: 1%;
      margin-top: 0;
      line-height: 30px;
      word-spacing: 4px;

      @include xl() {
        line-height: 35px;
      }

      @include tablet() {
        font-size: 4.7vw;
        line-height: 54px;

        @include xTablet() {
          font-size: 4.5vw;
          line-height: 57px;
          word-spacing: 11px;
        }
      }

      @include phone() {
        font-size: 5vw;
        line-height: 9.2vw;
      }
    }

    .questions {
      white-space: pre-line;
      font-size: 27.2px;
      font-weight: bold;
      padding-left: 1%;
      margin-top: 8%;
      line-height: 37px;
      letter-spacing: 5.7px;

      @include xl() {
        font-size: 2vw;
        line-height: 2.6vw;
      }

      @include tablet() {
        font-size: 4.7vw;
        margin-top: 14%;
        line-height: 66px;
        letter-spacing: 10.7px;
        width: 100%;

        @include xTablet() {
          margin-top: 9%;
          line-height: 68px;
        }
      }

      @include phone() {
        font-size: 6vw;
        margin-top: 25%;
        line-height: 10.5vw;
        letter-spacing: 5.7px;
      }
    }

    .note {
      text-align: left;
      font-weight: 300;
      font-size: 1.7vw;
      margin-top: 9%;
      line-height: 2vw;
      padding-left: 1%;
      word-spacing: 0.5vw;

      @include tablet() {
        font-size: 4.7vw;
        margin-top: 23%;
        line-height: 55px;

        @include xTablet() {
          font-size: 4.4vw;
          margin-top: 19%;
          line-height: 55px;
          word-spacing: 11px;
        }
      }

      @include phone() {
        font-size: 5vw;
        margin-top: 14%;
        line-height: 9vw;
      }
    }
  }
}
