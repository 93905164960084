@import "src/shared/styles/mixin";
@import "src/shared/styles/animation";

.about-container {
  animation: fade-in-up 0.4s;
  .custom-container {
    @include xl() {
      height: 80%;
    }
    @include tablet() {
      height: 83%;
    }
    @include phone() {
      height: 83%;
    }
  }

  .container {
    height: fit-content !important;
    padding-top: 20vh;
    padding-bottom: 10vh;
    min-height: unset !important;

    @include phone() {
      padding-top: 25vh;
      padding-bottom: 18vh;
    }

    @include tablet() {
      padding-bottom: 6vh;
    }
  }

  .mainSection-title {
    text-transform: capitalize !important;
    text-align: center;
    font-weight: 900 !important;
    letter-spacing: 13px;
    padding-top: 6%;
    margin-top: 0 !important;
    margin-bottom: 6% !important;
    font-size: 10rem !important;
    line-height: 2.8rem !important;

    @include xl() {
      font-size: 12rem !important;
      padding-top: 4.5%;
    }
    @include phone() {
      font-size: 11vw !important;
      letter-spacing: 2vw !important;
    }
    @include tablet() {
      font-size: 6rem !important;
      letter-spacing: 0.7rem;
      padding-top: 17% !important;
    }
  }

  .mainSection-description {
    font-size: 2rem !important;
    text-align: center !important;
    margin-bottom: 2% !important;
    letter-spacing: 2px !important;
    margin-top: -16px !important;

    @include phone() {
      font-size: 1rem !important;
      margin-top: -2px !important;
    }

    @include xl() {
      font-size: 2.5rem !important;
      margin-bottom: 0 !important;
    }

    @include tablet() {
      font-size: 2.1rem !important;
      font-weight: 500 !important;
      margin: 0 0 6% !important;
    }
  }

  .about-content {
    margin-top: 5%;
    padding-bottom: 91%;

    @include xl() {
      margin-top: 4%;
      padding-bottom: 82%;
    }

    @include phone() {
      margin-top: 4%;
      padding: 0 9% 91%;
    }

    @include tablet() {
      margin-top: 2%;
      padding: 0 12% 50%;
    }
    p {
      font-weight: 300;
      font-size: 20px;
      letter-spacing: 0.3px;

      @include phone() {
        font-weight: 300;
        font-size: 18px;
        letter-spacing: 0.2px;
        line-height: 24px;
      }

      @include xl() {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.5px;
      }

      @include tablet() {
        font-size: 28px;
      }
    }

    .au-image {
      width: 100%;
      height: 400px;
      padding: 3%;
      background: radial-gradient(
        115.57% 115.57% at 50% 100%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0) 100%,
        rgba(255, 255, 255, 0) 100%
      );
      border-radius: 20px;
      margin-top: 8%;
      margin-bottom: 4%;

      @include tablet() {
        height: 300px;
        margin-top: 17%;
      }

      @include phone() {
        height: 188px !important;
        margin-top: 22%;
      }

      @include phone() {
        height: 300px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
      }
    }

    h2 {
      margin-top: 11%;
      font-size: 1.65rem;
      margin-bottom: 0;

      @include xl() {
        margin-top: 20%;
        font-size: 2rem;
      }

      @include tablet() {
        margin-top: 28%;
        font-size: 2.3rem;
      }

      @include phone() {
        margin-top: 38%;
      }
    }

    h3 {
      margin-top: 2%;
      font-size: 1.3rem;
      font-weight: 500;
      margin-bottom: 2%;

      @include xl() {
        font-size: 1.5rem;
      }

      @include tablet() {
        margin-top: 4%;
        font-size: 1.8rem;
        margin-bottom: 3%;
      }

      @include phone() {
        margin-top: 4%;
        margin-bottom: 6%;
      }
    }

    .mission-title {
      margin-top: 8.5%;

      @include tablet() {
        margin-top: 14.5%;
      }

      @include phone() {
        margin-top: 19.5%;
      }
    }

    .vision-title {
      margin-top: 7.5%;
      @include tablet() {
        margin-top: 13.5%;
      }

      @include phone() {
        margin-top: 18.5%;
      }
    }
  }

  .custom-robot-background {
    margin-top: 12%;

    .custom-robot-image {
      z-index: 1;
    }

    .custom-overlay {
      background: rgb(0, 0, 0);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 1%,
        rgba(0, 144, 250, 0) 70%,
        rgb(35, 37, 41) 88%,
        rgb(34, 37, 41) 100%
      );
    }
  }
}
