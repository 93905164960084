@import "src/shared/styles/mixin";
@import "src/shared/styles/animation";

.contactUs-container {
  animation: fade-in-up 0.4s;
  .custom-container {
    @include xl() {
      height: 80%;
    }

    @include tablet() {
      height: 70%;
    }
  }

  .container {
    height: fit-content !important;
    padding-top: 20vh;
    padding-bottom: 10vh;
    min-height: unset !important;

    @include phone() {
      padding-top: 25vh;
      padding-bottom: 18vh;
    }

    @include tablet() {
      padding-bottom: 6vh;
    }
  }

  .mainSection-title {
    text-transform: capitalize !important;
    text-align: center;
    font-weight: 900 !important;
    letter-spacing: 13px;
    padding-top: 6%;
    margin-top: 0 !important;
    margin-bottom: 6% !important;
    font-size: 10rem !important;
    line-height: 2.8rem !important;

    @include xl() {
      font-size: 12rem !important;
      padding-top: 4.5%;
    }
    @include phone() {
      font-size: 13vw !important;
      letter-spacing: 2vw !important;
    }
    @include tablet() {
      font-size: 6rem !important;
      letter-spacing: 0.7rem;
      padding-top: 17% !important;
    }
  }

  .description {
    font-size: 2rem !important;
    text-align: center !important;
    margin-bottom: 2% !important;
    letter-spacing: 2px !important;
    margin-top: -16px !important;

    @include phone() {
      font-size: 1rem !important;
      margin-top: -2px !important;
    }

    @include xl() {
      font-size: 2.5rem !important;
      margin-bottom: 0 !important;
    }

    @include tablet() {
      font-size: 2.1rem !important;
      font-weight: 500 !important;
      margin: 0 0 6% !important;
    }
  }
}
