@import "src/shared/styles/mixin";

.bottomSection-container {
  margin-top: -98%;

  @include tablet() {
    margin-top: -110%;
  }

  @include phone() {
    margin-top: -188%;
  }

  .robot-image {
    position: absolute;
    top: -3%;
    left: 0;
    width: 100%;
    height: 70%;
    object-position: top;
    object-fit: cover;
    z-index: 1;

    @include phone() {
      height: 50%;
      top: 0;
    }

    @include tablet() {
      top: 0;
    }
  }

  .overlay {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 1%,
      rgba(0, 0, 0, 0) 20%,
      rgba(0, 0, 0, 0) 70%,
      rgb(35, 37, 41) 88%,
      rgb(34, 37, 41) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 71%;
    z-index: 1;

    @include phone() {
      height: 55%;
    }

    @include tablet() {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0,
        rgba(0, 144, 250, 0) 20%,
        rgba(0, 144, 250, 0) 70%,
        rgb(35, 37, 41) 88%,
        rgb(34, 37, 41) 100%
      );
    }
  }

  .bottom-content {
    padding-top: 90vh;
    z-index: 6;

    @include tablet() {
      padding-top: 146vh;
    }

    @include phone() {
      padding-top: 82vh;
      z-index: 5;
    }
  }
}
