@import "src/shared/styles/mixin";

input {
  border: 1px solid var(--accent);
  border-radius: 10px;
  width: 100%;
  min-height: 36px;
  font-style: italic;
  padding: 1% 8px;
  font-size: 18px;
  font-family:
    "Helvetica",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  background: transparent;
  margin-bottom: 12%;
  color: var(--text);
  transition: all 0.4s ease;

  @include phone() {
    min-height: 46px;
    font-size: 18px;
    padding: 1% 10px;
    width: 100%;
    margin-left: 0;
    margin-bottom: 7%;
  }

  @include tablet() {
    min-height: 55px;
    font-size: 29px;
    padding: 1% 10px;
    width: 100%;
    margin-bottom: 4%;
  }

  @include xl() {
    min-height: 50px !important;
    font-size: 28px;
    font-weight: 300;
    font-style: italic;
  }
}

textarea {
  width: 100%;
  border: 1px solid var(--accent);
  border-radius: 6px;
  min-height: 382px;
  font-size: 18px;
  background: transparent;
  margin-bottom: 2%;
  margin-top: 1%;
  padding: 2% 22px;
  resize: vertical;
  font-family:
    "Cera Pro",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  color: var(--color);
  font-weight: 300;
  font-style: italic;
  transition: border 0.4s ease;

  @include tablet() {
    min-height: 192px;
  }

  @include phone() {
    min-height: 509px;
    margin-bottom: 5%;
    padding: 3% 22px;
    font-size: 18px;
    border-radius: 12px;
  }

  @include tablet() {
    min-height: 509px;
    margin-bottom: 5%;
    padding: 3% 22px;
    font-size: 28px;
  }

  @include xl() {
    min-height: 508px !important;
    font-size: 28px;
  }
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4) !important;
}

input:focus,
textarea:focus {
  outline: none;
}
