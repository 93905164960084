@import "src/shared/styles/mixin";

.home-container {
  .home-background {
    background: transparent;
    position: absolute;
    width: 100%;
    height: calc(100% + 600px);
    object-position: top;
    object-fit: cover;
    top: -4%;
    left: 0;
    transition: transform 0.2s ease-out;

    @include phone() {
      top: 0;
      height: calc(100% + 300px);
    }
  }

  .main-sections {
    padding-bottom: 90%;
  }
}
