@import "src/shared/styles/mixin";

.main-button {
  border: none;
  border-radius: 15px;
  max-width: 400px;
  min-width: 295px;
  min-height: 50px;
  -webkit-backdrop-filter: blur(10px);
  background: var(--primary);
  transition: opacity 0.3s ease;
  cursor: pointer;
  font-weight: 700;
  color: white;
  font-size: 21px;
  line-height: 37px;
  padding: 0;
  margin: 0;
  background: radial-gradient(100% 100% at 100% 100%, #01efe4 0%, #0169fc 100%);
  box-shadow: 0 10px 20px rgba(0, 204, 204, 0.25);

  @include phone() {
    font-size: 24px;
    min-width: 294px;
    min-height: 60px;

    @include galaxyFold() {
      font-size: 16px;
      min-width: 80vw;
      min-height: 50px;
    }
  }

  @include tablet() {
    font-size: 2.88vw;
    min-width: 375px;
    min-height: 60px;
  }

  @include xTablet() {
    font-size: 32px;
    min-width: 53vw;
    min-height: 80px;
  }

  @include xl() {
    min-height: 70px;
    min-width: 380px;
  }
}
.main-button:hover {
  opacity: 0.8;
}

.main-button:active {
  outline: none;
}
