@import "src/shared/styles/mixin";

.white-dots-container {
  --active-dot-color: #fff;
  --dot-color: rgba(255, 255, 255, 0.4);

  .slick-track {
    display: flex !important;
  }
}
.slick-prev {
  left: -48px !important;
  @include xl() {
    left: -54px !important;
  }
}
.slick-next {
  right: -48px !important;
  @include xl() {
    right: -36px !important;
  }
}

.slick-prev,
.slick-next {
  width: 33px !important;
  height: 33px !important;

  @include xl() {
    width: 44px !important;
    height: 44px !important;
  }
}

.blue-dots-container {
  --active-dot-color: var(--primary);
  --dot-color: var(--secondary);

  .slick-slider {
    .slick-list {
      max-height: 465px;
      @include xl() {
        max-height: 628px;
      }
      @include phone() {
        max-height: 428px;
      }
      @include tablet() {
        max-height: 480px;
      }
    }
    .slick-slide {
      margin-right: 77px !important;
      margin-left: -50px !important;

      @include phone() {
        padding: 0 5vw !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
      }
      @include tablet() {
        padding: 0 5vw !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
      }
    }

    .slick-dots {
      bottom: -87px;
      z-index: 4;
      @include xl() {
        bottom: -113px !important;
      }
    }
  }
}

.slick-dots {
  bottom: -78px !important;
  z-index: 4;

  @include phone() {
    bottom: -36px !important;
  }

  @include xl() {
    bottom: -96px !important;
  }

  li {
    width: 30px;
    transition: all 0.4s ease;
    padding: 0;

    button {
      transition: all 0.4s ease;
      background: var(--dot-color) !important;
      border-radius: 50%;
      width: 14px !important;
      height: 14px !important;
      cursor: pointer !important;

      @include phone() {
        width: 15px !important;
        height: 15px !important;
      }

      @include tablet() {
        width: 20px !important;
        height: 20px !important;
      }

      @include xl() {
        width: 20px !important;
        height: 20px !important;
      }

      &:before {
        font-size: 0 !important;
      }
    }

    &.slick-active {
      width: 60px;
      @include phone() {
        width: 45px !important;
      }
      button {
        background: var(--active-dot-color) !important;
        border-radius: 22px;
        width: 45px !important;
        height: 14px;
        @include xl() {
          width: 60px !important;
          height: 20px !important;
        }
        @include phone() {
          width: 45px !important;
          height: 15px !important;
        }
        @include tablet() {
          width: 60px !important;
          height: 20px !important;
        }
      }
    }
  }
}
.slick-track {
  display: flex;
}

.slick-track .slick-slide {
  display: flex;
  height: auto;
}
