@import "src/shared/styles/mixin";
@import "src/shared/styles/animation";

.digitalMarketing-container {
  animation: fade-in-up 0.4s;

  .custom-welcome-label {
    font-size: 107px !important;
    margin-left: -1% !important;
    letter-spacing: 11px !important;
    line-height: 183px !important;
    padding-top: 11.5% !important;

    @include tablet() {
      font-size: 7.5vw !important;
      margin-left: -1% !important;
      letter-spacing: 11px !important;
      line-height: 136px !important;
      padding-top: 34.5% !important;
      text-align: center;
    }

    @include phone() {
      font-size: 7.8vw !important;
      margin-left: 0 !important;
      letter-spacing: 7px !important;
      line-height: 118px !important;
      padding-top: 45.5% !important;
      text-align: center;
      white-space: nowrap;
    }
  }

  .custom-welcome-description {
    width: 75% !important;

    @include tablet() {
      font-size: 41px !important;
      width: 100% !important;
      text-align: center !important;
      letter-spacing: 3.3px !important;
    }

    @include phone() {
      font-size: 15px !important;
      width: 100% !important;
      text-align: center !important;
      letter-spacing: 3.3px !important;
    }
  }

  .custom-welcome-image {
    padding-top: 0 !important;
    width: 70% !important;
    position: relative !important;
    top: -43vh !important;

    @include tablet() {
      padding-top: 0 !important;
      width: 137% !important;
      position: relative !important;
      top: -26vh !important;
    }

    @include phone() {
      padding-top: 0 !important;
      width: 100% !important;
      position: relative !important;
      top: -7vh !important;
      overflow: hidden;
      transform: scale(1.5);
      left: 9%;
    }
  }

  .custom-bottom-container {
    @include desktop() {
      bottom: 0;
    }
  }

  .bottom-section {
    @include tablet() {
      margin-top: -154%;
    }
  }

  .case-study {
    margin-top: -120%;
  }
}
