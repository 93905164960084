@import "../../shared/styles/mixin";

.categoryItem-container {
  justify-content: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  border: 1px solid white;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.4s ease;

  &.selected {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;

    p {
      text-align: center;
      background: radial-gradient(
        111.62% 232.73% at 103.19% 103.88%,
        #00ffe0 0%,
        #0057ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 30px;
    color: white;
    margin: 0;

    @include phone() {
      font-size: 16px;
    }

    @include tablet() {
      font-size: 18px;
    }
    @include xl() {
      font-size: 24px;
    }
  }
}
