@import "src/shared/styles/mixin";

.brief-container {
  min-height: 50vh;
  h2 {
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--dark-text);
    margin-top: 12%;
  }

  p {
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 1.5px;
    color: var(--gray-text);
  }

  .tagline {
    width: 52%;
    margin-bottom: 4%;

    @include phone() {
      width: 100%;
    }

    @include tablet() {
      width: 100%;
    }
  }
}

.w-50 {
  width: 60%;

  @include phone() {
    width: 100%;
  }

  @include tablet() {
    width: 100%;
  }
}

.w-100 {
  width: 100%;
}
