@import "src/shared/styles/mixin";

.details-container {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 5%,
    rgba(255, 255, 255, 1) 100%
  );
  padding-top: 6%;
  margin-top: -5%;

  @include tablet() {
    padding-top: 48%;
    margin-top: -13%;
  }

  @include phone() {
    padding-top: 30%;
    margin-top: -30%;
  }

  .left-section {
    min-height: 490px;

    @include tablet() {
      display: flex;
      flex-direction: column-reverse;
    }

    @include phone() {
      display: flex;
      flex-direction: column-reverse;
    }

    img {
      width: 70%;
      height: 109%;
      position: absolute;
      object-fit: contain;
      top: -6%;
      left: -4%;

      @include tablet() {
        width: 100%;
        height: 100%;
        position: relative;
        object-fit: contain;
        top: -5vh;
        left: 0;
      }

      @include phone() {
        width: 100%;
        height: 100%;
        position: relative;
        object-fit: contain;
        top: -5vh;
        left: 0;
      }
    }

    p {
      font-size: 21.6px;
      width: 44%;
      margin-left: 48%;
      white-space: pre-wrap;
      margin-top: 0;
      padding-top: 11%;
      line-height: 31px;
      color: var(--light-gray-text);
      font-weight: 300;

      @include tablet() {
        font-size: 3.8vw;
        width: 100%;
        padding-top: 7%;
        line-height: 5.2vw;
        padding-left: 11%;
        padding-right: 11%;
        margin-left: 0;
      }

      @include xTablet() {
        font-size: 3.6vw;
        padding-top: 11%;
        padding-left: 12%;
        word-spacing: 7px;
        padding-right: 12%;
      }

      @include phone() {
        font-size: 4.6vw;
        width: 100%;
        padding-top: 3%;
        line-height: 9.3vw;
        padding-left: 15%;
        padding-right: 10%;
        margin-left: 0;
        word-spacing: 5px;
      }

      @include xl() {
        font-size: 25.6px;
        width: 43%;
        padding-top: 5%;
        line-height: 35px;
      }
    }
  }

  .right-section {
    padding-top: 11.5%;
    padding-bottom: 10%;

    @include xl() {
      padding-top: 6%;
    }

    @include tablet() {
      display: flex;
      flex-direction: column;
      padding-top: 5%;
    }

    @include phone() {
      display: flex;
      flex-direction: column;
      padding-top: 3%;
      padding-bottom: 1%;
    }

    img {
      width: 33%;
      height: 90%;
      position: absolute;
      object-fit: contain;
      top: 0;
      right: 8.6%;
      min-height: 576px;

      @include tablet() {
        width: 100%;
        position: relative;
        top: 0;
        right: 0;
        min-height: unset;
      }

      @include phone() {
        width: 100%;
        position: relative;
        top: 0;
        right: 0;
        min-height: 546px;
      }
    }

    p {
      font-size: 21.4px;
      width: 43.7%;
      margin-left: 8.5%;
      white-space: pre-wrap;
      margin-top: 0;
      line-height: 30px;
      color: var(--light-gray-text);
      font-weight: 300;

      @include tablet() {
        font-size: 3.6vw;
        width: 100%;
        margin-left: 0;
        white-space: pre-wrap;
        line-height: 5.45vw;
        padding-right: 11%;
        padding-left: 12%;
        word-spacing: 7px;
        margin-top: 3%;
      }

      @include phone() {
        width: 100%;
        margin-left: 0;
        white-space: pre-wrap;
        padding-right: 13%;
        padding-left: 12%;
        margin-top: -11%;
        padding-bottom: 1%;
        font-size: 4.6vw;
        line-height: 9.3vw;
        word-spacing: 5px;
      }
      @include xl() {
        font-size: 25.6px;
        width: 43.5%;
        padding-top: 5%;
        line-height: 35px;
      }
    }
  }

  .custom-letsTalk {
    color: var(--light-gray-text);
    font-weight: 300;

    @include tablet() {
      margin-top: 2%;
    }

    @include phone() {
      margin-top: 6%;
    }

    @include xl() {
      font-size: 40px;
    }
  }
}
