@import "../../../../../shared/styles/mixin";

.caseListLoader-container {
  .categories {
    gap: 12px;
    margin-bottom: 10%;
    flex-wrap: wrap;
  }

  .list {
    @include phone() {
      margin-top: 20%;
    }
  }
}
