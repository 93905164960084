@import "src/shared/styles/mixin";

.industriesWeServe-container {
  padding-bottom: 90%;
  padding-top: 12%;

  @include tablet() {
    padding-bottom: 110%;
  }

  @include phone() {
    padding-bottom: 140%;
  }

  @include xTablet() {
    padding-bottom: 110%;
  }

  @include xl() {
    padding-bottom: 84%;
  }

  .text-content {
    z-index: 2;

    h2 {
      font-size: 90px;
      text-transform: uppercase;
      text-align: center;
      margin-top: 0;
      margin-bottom: 4%;

      @include tablet() {
        font-size: 7vw;
        margin-top: 10%;
        margin-bottom: 4%;
      }

      @include phone() {
        font-size: 10vw;
        margin-top: 23%;
        margin-bottom: 16%;
        padding: 0 21%;
      }

      @include xl() {
        font-size: 111px;
      }
    }

    .industry-item {
      margin-bottom: 2%;
      margin-top: 3.1%;

      @include phone() {
        margin-bottom: 14%;
      }

      &:nth-child(3) {
        margin-bottom: 4%;

        @include phone() {
          margin-bottom: 17%;
        }
      }

      &:nth-child(5) {
        margin-bottom: 4%;

        @include phone() {
          margin-bottom: 14%;
        }
      }

      &:nth-child(7) {
        margin-bottom: 4%;

        @include phone() {
          margin-bottom: 15%;
        }
      }

      &:nth-child(9) {
        margin-bottom: 4%;

        @include phone() {
          margin-bottom: 18%;
        }
      }

      &:nth-child(11),
      &:nth-child(12) {
        margin-top: 1.9%;
        margin-bottom: 5%;
      }

      &.left {
        padding-left: 13%;
        padding-right: 8%;
      }

      &.right {
        padding-left: 8%;
        padding-right: 13%;

        @include phone() {
          padding-left: 12%;
        }
      }

      h3 {
        font-size: 47px;
        letter-spacing: 4px;
        margin-bottom: 0;
        margin-top: 0;

        @include phone() {
          font-size: 9.5vw;
        }

        @include tablet() {
          font-size: 9vw;
        }

        @include xl() {
          font-size: 60px;
        }
      }

      p {
        margin-top: 0;
        line-height: 28px;
        font-size: 20px;
        letter-spacing: 0.8px;
        font-weight: 300;

        @include tablet() {
          line-height: 4.9vw;
          font-size: 3.7vw;
          width: 97%;
        }

        @include phone() {
          margin-top: 3%;
          font-size: 4.6vw;
          font-weight: 300;
        }

        @include xl() {
          line-height: 32px;
          font-size: 25px;
        }
      }
    }

    .custom-letsTalk {
      @include tablet() {
        margin-right: 8%;
        text-align: right;
        margin-top: 0;
      }

      @include phone() {
        margin-top: 11%;
        text-align: center;
      }

      @include xl() {
        font-size: 40px;
      }
    }
  }
}
