@import "src/shared/styles/mixin";

.blogContent-container {
  @include phone() {
    padding: 0 8%;
  }
  h1 {
    color: var(--gray-text);
    margin: 0 0 8%;
    font-size: 42px;

    @include phone() {
      font-size: 40px;
      line-height: 50px;
    }

    @include xl() {
      font-size: 60px;
    }
  }

  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family:
      "Cera Pro",
      "Helvetica",
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      sans-serif;
  }

  p {
    line-height: 38px;
  }

  .cover {
    width: 100%;
    height: 340px;
    object-fit: cover;
    border-radius: 12px;
    background: var(--image-background);

    @include phone() {
      height: 464px;
    }

    @include xl() {
      height: 464px;
    }
  }

  .blog-content {
    img {
      width: 100%;
      height: 320px;
      object-fit: cover;
      border-radius: 12px;
      background: var(--image-background);

      @include xl() {
        height: 464px;
      }
    }

    p {
      color: var(--dark-text);
      font-size: 18px;

      @include xl() {
        font-size: 21px;
      }
    }
  }

  .tag-container {
    margin-top: 2%;
    flex-wrap: wrap;
    column-gap: 12px;
    row-gap: 8px;
    width: 100%;
  }
}
