@import "src/shared/styles/mixin";

.ourProcess-container {
  margin-top: 8%;
  width: 100%;
  padding: 0 9%;
  @include notDesktop() {
    padding: 0 4%;

    .svgTopImage {
      top: 8% !important;
    }

    .svgBottomImage {
      bottom: 17% !important;
      left: -33% !important;
    }
  }
  .text-container {
    padding-left: 1%;

    @include notDesktop() {
      padding-left: 3%;
      padding-right: 0;
      margin-top: 4%;
    }

    h2 {
      font-size: 26px;
      font-weight: bold;
      letter-spacing: 1px;
      color: var(--dark-text);
    }

    p {
      font-size: 22px;
      font-weight: 300;
      letter-spacing: 1.5px;
      color: var(--gray-text);
      white-space: pre-line;
      line-height: 30px;
    }
  }

  .main-image {
    width: 100%;
    z-index: 3;

    @include notDesktop() {
      margin-top: 20%;
    }

    &.mobile-image {
      width: 72%;
      margin-left: 26%;
    }
  }
  .mobile-main-image {
    width: 35%;
    z-index: 3;

    @include notDesktop() {
      margin-top: 20%;
    }
  }
  .svgTopImage {
    bottom: 0;
    top: -8%;
    right: 0;
    transform: translateX(63%);
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 1;
  }

  .svgBottomImage {
    position: absolute;
    bottom: 0;
    left: 0;
    height: auto;
    z-index: 2;
    transform: translateX(-100%);
  }

  .mobile-text-container {
    padding-left: 0;
    padding-right: 0;
  }

  .one-mobile-image {
    width: 57%;

    @include notDesktop() {
      width: 80%;
      margin-top: 20%;
    }
  }
}
