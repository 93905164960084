@import "src/shared/styles/mixin";

.storyVision-container {
  padding: 9% 0 2%;
  background-color: white;
  color: var(--light-gray-text);

  @include xl() {
    padding: 4% 0 2%;
  }

  @include tablet() {
    padding: 29% 0 2%;
  }

  @include phone() {
    padding: 17% 0 2%;
  }

  img {
    width: 100%;
    object-fit: contain;
    transform: scale(1.1);
    left: 2%;
    top: -15px;

    @include phone() {
      top: 0;
      left: 0;
      transform: scale(1);
    }

    @include tablet() {
      top: 0;
      left: 0;
      transform: scale(1);
    }
  }

  .text-content {
    font-size: 27px;
    font-weight: 300;
    padding: 0 9% 0 4.6%;
    line-height: 30px;
    letter-spacing: 0.2px;

    @include xl() {
      font-size: 32px;
      line-height: 36px;
    }

    @include phone() {
      font-size: 18px;
      padding: 0 10% 0 10%;
      line-height: 41px;
      letter-spacing: 2.2px;
    }

    @include galaxyFold() {
      font-size: 12px;
      line-height: 26px;
    }

    @include tablet() {
      font-size: 46px;
      padding: 0 12% 0 12%;
      line-height: 54px;
      letter-spacing: 1.9px;
    }

    p:nth-child(1) {
      margin-top: 1%;
    }

    p:nth-child(2) {
      margin-top: 17%;
      margin-bottom: 20%;

      @include phone() {
        margin-top: 18%;
        margin-bottom: 12%;
      }
    }
  }

  .custom-letsTalk {
    @include xl() {
      font-size: 40px;
    }
    @include galaxyFold() {
      font-size: 21px;
    }
  }
}
