@import "src/shared/styles/mixin";
@import "src/shared/styles/animation";

.caseStudyList-container {
  background: var(--primary);
  background: radial-gradient(
    111.62% 232.73% at 103.19% 103.88%,
    var(--secondary) 0%,
    var(--primary) 100%
  );
  animation: fade-in-up 0.4s;
  padding: 20vh 9% 6vh;
  min-height: 100vh;

  .mainSection-title {
    text-align: center;
    font-weight: 900;
    letter-spacing: 1vw;
    line-height: 12vw;
    margin-top: 0;
    margin-bottom: 6%;
    font-size: 12vw;
    text-transform: uppercase;
    padding-top: 6%;
    padding-bottom: 6vh;

    @include tablet() {
      letter-spacing: 4px;
    }

    @include mdDesktop() {
      letter-spacing: 0.5vw;
    }

    @include xl() {
      padding-top: 4.5%;
    }

    @include tablet() {
      padding-top: 34.5%;
    }

    @include phone() {
      letter-spacing: 1px;
      padding-top: 20%;
    }
  }

  .categoryList-container {
    gap: 12px;
    margin-bottom: 10%;
    flex-wrap: wrap;
  }
}
