@import "../../../shared/styles/mixin";

.footer {
  background: white;
  padding: 5.5% 7%;

  @include phone() {
    padding: 22% 0 10% 4%;
  }

  @include tablet() {
    padding: 10% 0 10% 4%;
  }

  .logo {
    width: 54%;
    margin-bottom: 10%;
    @include xl() {
      margin-bottom: 8%;
    }

    @include phone() {
      width: 100%;
      margin-bottom: 19%;
    }

    @include tablet() {
      width: 100%;
      margin-bottom: 20%;
    }
  }

  p {
    color: var(--dark-text);
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 2%;

    @include phone() {
      font-size: 16px;
    }

    @include tablet() {
      font-size: 16px;
    }

    @include xl() {
      font-size: 16px;
      margin-left: 2%;
    }
  }

  .menu-list {
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
    width: 30%;
    margin-left: 2.6%;
    margin-top: 2%;

    @include xl() {
      width: 38%;
    }

    @include mdDesktop() {
      width: 40%;
    }

    @include phone() {
      margin-top: 2.8%;
      width: 100%;
      padding-left: 0;
    }

    @include tablet() {
      margin-top: 2.8%;
      width: 64%;
      padding-left: 6%;
    }

    .sub-list {
      display: flex;
      flex-direction: column;
      a {
        font-size: 12px;
        text-transform: capitalize;
        align-self: self-start;
        margin-bottom: 27px;
        text-decoration: none;
        color: var(--dark-text);
        font-weight: 300;
        transition: color 0.4s ease;

        @include phone() {
          font-size: 12px;
          margin-bottom: 30px;
        }

        @include tablet() {
          font-size: 16px;
          margin-bottom: 39px;
        }

        @include xl() {
          font-size: 16px;
          margin-bottom: 35px;
        }
        &:hover {
          color: var(--primary);
        }
      }
    }
  }

  .social-list {
    display: grid;
    margin-top: 2.2%;
    grid-template-columns: 1fr 1fr;
    gap: 45px;

    @include tablet() {
      display: none;
    }

    @include phone() {
      display: none;
    }

    @include xl() {
      gap: 60px;
    }

    .icon-list {
      display: flex;
      flex-direction: column;
      gap: 12px;

      button {
        background: transparent;
        padding: 0;
        margin: 0;
        svg {
          color: var(--dark-text);
          transition: color 0.4s ease;
          width: 22px;
          height: 22px;
          cursor: pointer;

          @include phone() {
            width: 30px;
            height: 30px;
          }

          @include xl() {
            width: 30px;
            height: 30px;
          }
        }

        &:hover {
          .whatsapp {
            color: #25d366;
          }
          .facebook {
            color: #1877f2;
          }
          .youtube {
            color: #ff0000;
          }
          .linkedin {
            color: #0a66c2;
          }
          .instagram {
            color: #833ab4;
          }
        }
      }
    }
  }

  .tablet-icons-container {
    gap: 23px;
    margin-top: -7%;
    min-width: 100%;

    button {
      background: transparent;
    }

    &.ml-9 {
      margin-left: -9%;
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }

  .company-mobile {
    font-size: 12px;
    width: 100%;
    margin-top: 7%;
  }

  .social-button {
    background: transparent;
    svg {
      width: 30px;
      height: 30px;
    }
  }

  .bottom-social {
    @include tablet() {
      padding-left: 15px;
    }
  }
}
