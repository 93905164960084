@import "src/shared/styles/mixin";

.buttons-container {
  position: fixed;
  right: 50px;
  bottom: 4%;
  display: grid;
  z-index: 4;
  transition: 300ms;

  @include tablet() {
    right: 25px;
  }

  @include phone() {
    right: 25px;
  }

  .circle-button {
    width: 40px;
    height: 40px;
    cursor: pointer;
    background: radial-gradient(
      111.62% 232.73% at 103.19% 103.88%,
      var(--secondary) 0%,
      var(--primary) 100%
    );
    border-radius: 35px;
    margin-top: 12px;
    transition: all 0.4s ease;
    @include hover() {
      filter: grayscale(100%);
      opacity: 0.8;
    }

    @include phone() {
      width: 50px;
      height: 50px;
    }

    @include tablet() {
      width: 50px;
      height: 50px;
    }
  }

  .call-button {
    transform: translateY(45px);
  }

  .scroll-to-top {
    opacity: 0; // Start invisible
    transform: translateY(20px);
    visibility: hidden;

    &.visible {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }
}
