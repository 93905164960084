@import "../../../shared/styles/mixin";
@import "../../../shared/styles/animation";

.notFound-container {
  width: 100%;
  padding-top: 10%;
  padding-bottom: 10%;
  height: 100vh;
  background: var(--primary);
  color: var(--color);
  flex-direction: column;
  animation: fade-in-up 0.4s;

  h1 {
    font-size: 240px;
    font-weight: bold;
    text-transform: uppercase;
    animation: opacity 10s infinite;
    margin-bottom: 0;

    @include phone() {
      font-size: 60px;
    }

    @include tablet() {
      font-size: 80px;
    }
  }

  @keyframes opacity {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.6;
    }
  }

  p {
    margin-top: 0;
    font-size: 60px;
    text-align: center;
    font-weight: 300;

    @include phone() {
      font-size: 26px;
    }

    @include tablet() {
      font-size: 32px;
    }
  }
}
