@import "src/shared/styles/mixin";

.ourProcessItem-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  padding: 1% 6% 12% 25.5%;
  max-height: 12.75rem;
  background: radial-gradient(
    117.89% 771.88% at 0% 0%,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(10px);
  box-shadow:
    0 4px 9px 0 rgba(0, 224, 255, 0.25),
    0px -10px 32px -10px rgba(255, 255, 255, 0.25);

  @include phone() {
    max-height: 15.625rem;
    min-height: 15.625rem;

    @include smHeight() {
      max-height: 16.625rem;
      min-height: 16.625rem;
    }
    padding: 0 6% 11% 44.5%;
  }

  @include tablet() {
    max-height: 16.25rem;
  }

  @include xl() {
    max-height: 17.0625rem;
  }

  p {
    margin: 0;
  }

  .description {
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.4375rem;
    margin-top: 11%;

    @include phone() {
      font-size: 4vw;
      margin-top: 20%;
      line-height: 6.1vw;

      @include smHeight() {
        font-size: 1rem;
      }
    }

    @include tablet() {
      font-size: 3.3vw;
      line-height: 4.2vw;
      white-space: pre-line;
    }

    @include xl() {
      font-size: 1.5rem;
      line-height: 1.875rem;
    }
  }

  .title {
    position: absolute;
    bottom: -0.1875rem;
    right: 3%;
    font-size: 1.8125rem;
    font-weight: 300;
    font-style: italic;
    margin-top: 6%;
    margin-bottom: -0.1875rem;
    text-shadow:
      0 2px 4px rgba(0, 119, 119, 0.25),
      0px -2px 4px rgba(255, 255, 255, 0.5);

    @include phone() {
      font-size: 5.3vw;
      bottom: 7%;
      right: 3%;
    }

    @include tablet() {
      font-size: 6vw;
      bottom: 6%;
    }

    @include xl() {
      font-size: 2.5rem;
    }
  }

  .number {
    position: absolute;
    left: 3%;
    bottom: -52%;
    font-size: 13.75rem;
    font-weight: 900;

    @include phone() {
      font-size: 44vw;
      left: 5%;
      bottom: 28%;

      @include mdHeight() {
        bottom: 0;
      }

      @include smHeight() {
        bottom: 0;
      }
    }

    @include tablet() {
      font-size: 40vw;
      line-height: 42vw;
      left: 3%;
      top: 0;
    }

    @include xl() {
      font-size: 17.75rem;
    }

    &.num-2 {
      left: -7%;
    }

    &.num-3 {
      left: -5%;
    }

    &.num-4 {
      left: -9%;
    }
  }

  &.home {
    @include phone() {
      padding: 0 6% 11% 34.5%;
    }

    .description {
      @include phone() {
        font-size: 1.025rem;
        margin-top: 8%;
      }

      @include tablet() {
        font-size: 1.5rem;
        line-height: 1.875rem;
      }
    }

    .title {
      @include phone() {
        left: 3%;
      }
      @include tablet() {
        font-size: 2.5rem;
      }
    }
    .description {
      @include phone() {
        font-size: 4vw;
        margin-top: 11%;
        word-spacing: 5px;
        line-height: 5.4vw;
      }
    }
    .number {
      @include phone() {
        font-size: 44vw;
        left: -2%;

        @include sm() {
          font-size: 35vw;
          line-height: 30vw;
        }
        &.num-2 {
          left: -7%;
        }

        &.num-3 {
          left: -5%;
        }

        &.num-4 {
          left: -9%;
        }
        top: 6%;
        line-height: 40vw;
      }

      @include tablet() {
        font-size: 17.5rem;
        line-height: 28vw;
      }
    }
  }
}
