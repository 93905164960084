@import "src/shared/styles/mixin";

.technologies-container {
  margin-top: 33.5vh;
  z-index: 1;
  padding: 0 7% 20%;

  @include phone() {
    margin-top: 28.5vh;
    padding: 0 9% 130%;
  }

  @include tablet() {
    margin-top: 17.5vh;
    padding: 0 5% 90%;
  }

  @include xl() {
    margin-top: 33vh;
  }

  .technologies-title {
    white-space: pre-wrap;
    text-transform: uppercase;
    text-align: right;
    font-weight: 900;
    font-size: 5rem;
    letter-spacing: 0.8125rem;
    line-height: 5.625rem;
    margin-bottom: 5%;

    @include xl() {
      font-size: 7rem;
      line-height: 7.1rem;
      letter-spacing: 0.8rem;
      margin-bottom: 6%;
      margin-right: 1%;
    }

    @include phone() {
      font-size: 9vw;
      line-height: 3.5rem;
      margin-bottom: 23%;
      letter-spacing: 0.375rem;
      font-weight: 900;
    }

    @include tablet() {
      font-size: 4.125rem;
      letter-spacing: 0.5625rem;
      line-height: 5.25rem;
      margin-bottom: 10%;
    }
  }

  .technologiesImages-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    row-gap: 2.5rem;
    column-gap: 4.125rem;

    @include xl() {
      row-gap: 3.3125rem;
      column-gap: 5.625rem;
    }

    div {
      width: 4.375rem;
      height: 4.8125rem;
      object-fit: contain;
      align-self: center;
      justify-self: center;

      @include phone() {
        width: 24vw;
        height: 7.5rem;
      }

      @include tablet() {
        width: 13vw;
        height: 8rem;
      }

      @include xl() {
        width: 6.1875rem;
        height: 6.5rem;
      }

      img {
        width: 100%;
        height: 100%;
      }

      &.small-image {
        width: 5rem;
        height: 3.75rem;

        @include phone() {
          width: 25vw;
          height: 5.125rem;
        }

        @include tablet() {
          width: 13vw;
          height: 5.4375rem;
        }

        @include xl() {
          width: 5.9375rem;
          height: 5.4375rem;
        }
      }
    }
  }
}
