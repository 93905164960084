@mixin phone {
  @media (max-width: 743px) {
    @content;
  }
}

@mixin phoneSmallHeight {
  @media (max-width: 743px) and (max-height: 880px) {
    @content;
  }
}

@mixin xxs {
  @media (min-width: 0px) and (max-width: 375px) {
    @content;
  }
}

@mixin xs {
  @media (min-width: 0px) and (max-width: 539px) {
    @content;
  }
}

@mixin sm {
  @media (min-width: 540px) and (max-width: 768px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 744px) and (max-width: 1025px) {
    @content;
  }
}

@mixin mdTablet() {
  @media (min-width: 750px) and (max-width: 850px) {
    @content;
  }
}
@mixin xTablet {
  @media (min-width: 850px) and (max-width: 1025px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) and (max-width: 992px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 992px) and (max-width: 1500px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1441px) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1100px) {
    @content;
  }
}

@mixin notDesktop {
  @media (max-width: 1100px) {
    @content;
  }
}

@mixin mdDesktop {
  @media (min-width: 1025px) and (max-width: 1255px) {
    @content;
  }
}

@mixin smHeight {
  @media (max-height: 680px) {
    @content;
  }
}

@mixin mdHeight {
  @media (min-height: 680px) and (max-height: 800px) {
    @content;
  }
}

@mixin bgHeight {
  @media (min-height: 800px) {
    @content;
  }
}

// devices

@mixin iphonePlus {
  @media (min-height: 700px) and (max-height: 745px) {
    @content;
  }
}

@mixin galaxyFold {
  @media (max-width: 290px) {
    @content;
  }
}

@mixin s8 {
  @media (max-width: 364px) and (min-height: 738px) and (min-width: 356px) and (max-height: 744px) {
    @content;
  }
}

@mixin nestHub {
  @media (max-width: 1040px) and (min-height: 580px) and (min-width: 1000px) and (max-height: 640px) {
    @content;
  }
}

// hover

@mixin hover {
  @media not all and (pointer: coarse) {
    &:hover {
      @content;
    }
  }
}
