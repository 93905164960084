@import "src/shared/styles/mixin";

.digitalServices-container {
  padding-bottom: 120%;
  background: white;
  @include tablet() {
    padding-bottom: 110%;
  }
  @include tablet() {
    padding-bottom: 110%;
  }
  @include phone() {
    padding-bottom: 130%;
  }

  .custom-background-image {
    @include tablet() {
      margin-top: -17%;
      z-index: 1;
    }
  }

  .page-content {
    padding: 16% 9% 0;

    @include tablet() {
      position: relative;
      z-index: 2;
      padding: 9% 0 0;
    }

    @include phone() {
      padding: 0;
    }

    .description {
      padding: 0 14% 0 15%;
      font-size: 24px;
      white-space: pre-line;
      font-weight: 300;
      letter-spacing: 1.4px;
      margin-bottom: 12.8%;

      @include tablet() {
        padding: 0 12% 0 12%;
        font-size: 46px;
        margin-bottom: 22.8%;
        line-height: 54px;
      }

      @include phone() {
        padding: 0 7% 0 12%;
        font-size: 20px;
        margin-bottom: 12.8%;
      }
    }
    h2 {
      font-size: 60.4px;
      letter-spacing: 6px;
      text-align: center;

      @include tablet() {
        font-size: 8.2vw;
      }

      @include phone() {
        font-size: 7vw;
        letter-spacing: 6px;
        text-align: center;
      }
    }

    .list-container {
      width: 92%;
      margin-left: 4%;
      padding-bottom: 7%;

      @include tablet() {
        padding-bottom: 16%;
      }

      &.pb-20 {
        padding-bottom: 16% !important;
      }

      .custom-brand {
        margin-top: 4.5% !important;
        margin-bottom: 0 !important;
        min-height: 150px !important;
        background: radial-gradient(
          117.89% 771.88% at 0% 0%,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        backdrop-filter: blur(25px);

        @include phone() {
          min-height: 132px !important;
        }

        @include tablet() {
          min-height: 265px !important;
        }

        p {
          font-size: 35px !important;
          letter-spacing: 4px !important;
          font-weight: 300 !important;
          margin: 0;

          @include tablet() {
            font-size: 7.4vw !important;
          }

          @include phone() {
            font-size: 28px !important;
          }
        }
      }
    }
  }
}
