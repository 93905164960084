@import "src/shared/styles/mixin";

.caseStudyItem-container {
  background: radial-gradient(
    111.62% 232.73% at 103.19% 103.88%,
    rgba(0, 255, 224, 0.7) 0%,
    rgba(0, 87, 255, 0.7) 100%
  );
  border-radius: 35px;
  max-height: 465px;
  height: 465px;
  overflow: hidden;
  text-decoration: none;
  &.small-version {
    @include desktop() {
      height: 228px;
    }
    h2 {
      font-size: 18px !important;
      letter-spacing: 1.4px !important;
      @include xl() {
        font-size: 30px !important;
      }
    }

    p {
      font-size: 16px !important;
      @include xl() {
        font-size: 20px !important;
      }
    }

    button {
      font-size: 16px !important;
      @include xl() {
        font-size: 18px !important;
      }
    }
  }
  @include xl() {
    max-height: 628px;
    height: 628px;

    &.small-version {
      max-height: 328px;
      height: 328px;
    }
  }

  @include phone() {
    height: 428px;
    max-height: 428px;
  }

  @include tablet() {
    height: 480px;
    min-height: 480px;
    max-height: 480px;
  }

  .text-container {
    padding: 0 0 0 8%;

    &.p-5 {
      padding: 0 0 0 5%;
    }

    @include lg() {
      padding: 0 0 0 10%;
    }

    h2,
    p {
      text-decoration: none;
      color: var(--color);
      white-space: pre-wrap;
    }

    h2 {
      font-size: 40px;
      letter-spacing: 7px;
      margin-top: 8%;
      margin-bottom: 4%;
      text-align: left;
      font-weight: 500;
      font-family: "Cera Pro", serif;
      text-transform: capitalize;

      @include phone() {
        font-size: 20px;
        letter-spacing: 1px;
        margin-top: -14%;
        margin-bottom: 4%;
        text-align: left;
        font-weight: bold;
      }

      @include tablet() {
        font-size: 37px;
        letter-spacing: 3px;
        margin-top: 30%;
        margin-bottom: 4%;
      }

      @include xl() {
        margin-top: 4%;
        font-size: 60px;
      }
    }

    p {
      font-size: 26px;
      font-weight: 200;
      line-height: 40px;
      margin: 0;
      font-family: "Cera Pro", serif;

      @include phone() {
        font-size: 20px;
        line-height: 28px;
        padding-top: 8%;
      }

      @include tablet() {
        font-size: 26px;
      }
      @include xl() {
        font-size: 30px;
      }
    }

    button {
      font-size: 20px;
      font-weight: 300;
      background: transparent;
      border: 1px solid white;
      border-radius: 8px;
      padding: 1% 4%;
      color: white;
      margin-top: 8%;
      @include xl() {
        font-size: 30px;
      }
      @include phone() {
        font-size: 18px;
        top: 10vh;
        position: relative;
      }

      @include tablet() {
        font-size: 29px;
        margin-top: 11%;
      }
    }
  }
}
