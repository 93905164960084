@import "src/shared/styles/mixin";

.phoneSection-container {
  display: grid;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 1%,
    rgba(255, 255, 255, 1) 100%
  );
  padding: 0 8% 9%;

  @include tablet() {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0;
    padding: 0;
  }

  @include phone() {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0;
    padding: 0;
  }

  p {
    font-size: 20px;
    width: 50%;
    justify-self: end;
    color: var(--light-gray-text);
    white-space: pre-wrap;
    line-height: 30px;
    letter-spacing: 0.1px;
    font-weight: 300;
    margin-top: 12%;

    @include tablet() {
      font-size: 3.8vw;
      width: 100%;
      margin-top: 10%;
      line-height: 5.3vw;
      padding: 13% 12% 0;
      font-weight: 300;
    }

    @include phone() {
      font-size: 5vw;
      width: 97%;
      margin-top: 10%;
      line-height: 7.2vw;
      padding: 6% 14% 0;
      white-space: pre-line;
    }

    @include xl() {
      font-size: 26px;
      margin-top: 0;
    }
  }

  img {
    position: absolute;
    width: 56%;
    height: 97%;
    object-fit: contain;
    transform: scale(1.2);

    @include tablet() {
      position: relative;
      top: 5vh;
      left: 3%;
      width: 100%;
      margin-bottom: 5vh;
    }

    @include phone() {
      position: relative;
      top: 5vh;
      left: 3%;
      width: 100%;
      margin-bottom: 5vh;
    }
  }
}
