@import "src/shared/styles/mixin";

.ourTechnicalExpertise-container {
  .page-content {
    padding: 9% 12% 88%;

    @include tablet() {
      padding: 17% 2% 120vh;
    }

    @include phone() {
      padding: 19% 0 140%;
    }

    h2 {
      font-size: 80px;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 5%;

      @include tablet() {
        font-size: 9vw;
      }

      @include xl() {
        font-size: 97px;
      }

      @include phone() {
        font-size: 11vw;
        margin-bottom: 19%;
      }
    }

    .technical-item {
      padding: 0 5%;

      @include phone() {
        flex-direction: column;
        margin-bottom: 25% !important;
      }

      &:nth-child(3) {
        margin-bottom: 0;

        @include tablet() {
          margin-bottom: 11%;
        }
      }

      &:nth-child(4) {
        @include tablet() {
          margin-bottom: 12%;
        }
      }

      &:nth-child(5) {
        margin-bottom: 3%;
      }

      &:nth-child(6) {
        @include tablet() {
          margin-bottom: 9%;
        }
      }

      img {
        padding: 2%;
        width: 120px;
        margin-right: 6%;

        @include phone() {
          width: 142px;
        }

        @include tablet() {
          min-width: 17vw;
          margin-right: 14%;
          margin-top: -53%;
        }

        @include xl() {
          padding: 0 2%;
          width: 150px;
          height: 160px;
          margin-right: 5%;
        }
      }

      h3 {
        font-size: 60px;
        text-transform: uppercase;
        letter-spacing: 5px;

        @include tablet() {
          font-size: 8vw;
        }

        @include phone() {
          font-size: 8vw;
          text-align: center;
        }

        @include xl() {
          font-size: 75px;
          font-weight: 900;
        }
      }

      p {
        font-size: 25px;
        font-weight: 300;
        width: 92.5%;
        margin-top: -4.3%;
        letter-spacing: 1px;
        line-height: 30px;

        @include tablet() {
          font-size: 4vw;
          margin-top: -3.3%;
          letter-spacing: 3.8px;
          line-height: 5.4vw;
        }

        @include phone() {
          font-size: 5vw;
          font-weight: 300;
          width: 84%;
          margin-top: -4.3%;
          letter-spacing: 1px;
          line-height: 9.5vw;
          margin-left: 8%;
        }

        @include xl() {
          font-size: 31px;
          line-height: 34px;
        }
      }
    }

    .note {
      font-size: 22px;
      font-weight: 300;
      padding: 0 1.5%;
      margin-top: 13%;
      letter-spacing: 0.2px;
      line-height: 31px;
      margin-bottom: 12%;

      @include tablet() {
        font-size: 3.9vw;
        padding: 0 10.5%;
        margin-top: 15%;
        line-height: 5.2vw;
      }

      @include phone() {
        font-size: 4vw;
        font-weight: 300;
        margin-top: -7%;
        letter-spacing: 0.2px;
        line-height: 9.2vw;
        margin-bottom: 12%;
        padding: 0 13%;
      }
      @include xl() {
        font-size: 26.5px;
        line-height: 37px;
      }
    }

    .custom-letsTalk {
      @include phone() {
        margin-top: 12%;
      }
      @include xl() {
        font-size: 40px;
      }
    }
  }
}
