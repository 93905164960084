@import "src/shared/styles/mixin";

.insightCarouselItem-container {
  width: 14.2vw;
  margin-right: 1.1vw;
  border-radius: 20px;
  min-height: 385px;
  padding: 7%;
  display: grid;
  background: radial-gradient(
    111.62% 232.73% at 103.19% 103.88%,
    rgba(0, 255, 224, 0.8) 0%,
    rgba(0, 87, 255, 0.8) 100%
  );

  @include phone() {
    width: 63.6vw;
    margin-left: 4.4vw;
  }

  @include xl() {
    min-height: 440px;
    width: 14.6vw;
  }

  @include mdDesktop() {
    width: 22vw;
  }

  @include tablet() {
    width: 22vw;
  }

  img {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    aspect-ratio: 1/1;
    background: var(--image-background);
  }

  h2,
  p {
    color: var(--color);
  }

  h2 {
    text-align: left;
    font-size: 16px;
    margin-bottom: 0;

    @include xl() {
      font-size: 20px;
    }
  }

  p {
    text-align: left;
    font-weight: 300;
    font-size: 14px;
    align-self: end;
    margin-bottom: 0;

    @include xl() {
      font-size: 16px;
    }
  }
}
