@import "src/shared/styles/mixin";

.links-container {
  background: rgb(99, 214, 244);
  background: linear-gradient(
    180deg,
    rgba(0, 230, 229, 1) 0%,
    rgba(0, 186, 237, 0.5) 50%,
    rgba(0, 157, 242, 0) 100%
  );
  min-height: calc(100vh + 6.25rem);
  margin-top: -2vh;
  position: relative;
  padding-bottom: 12rem;

  @include xl() {
    margin-top: -6.25rem;
  }

  @include tablet() {
    min-height: calc(100vh + 18.25rem) !important;
    padding-bottom: 18rem;

    @include xTablet() {
      min-height: calc(100vh + 10rem) !important;
    }

    @include smHeight() {
      min-height: calc(100vh + 25rem) !important;
    }

    @include mdHeight() {
      min-height: calc(100vh + 20rem) !important;
    }
  }

  @include phone() {
    min-height: 124vh;

    @include smHeight() {
      min-height: calc(100vh + 10rem) !important;
    }

    @include galaxyFold() {
      min-height: 100vh !important;
    }
  }

  @include xs() {
    min-height: calc(100vh + 10rem);

    @include bgHeight() {
      min-height: 100vh !important;
    }
  }

  @include desktop() {
    min-height: calc(100vh + 18rem);

    @include xl() {
      margin-top: -5.6rem;
      min-height: calc(100vh + 8rem);
    }
  }

  .clouds {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .text-center {
    position: absolute;
    width: 100%;
    bottom: -1%;
    left: 0;
    overflow-x: hidden;
    text-align: center;
    z-index: 2;

    @include phone() {
      bottom: 6%;
    }

    @include tablet() {
      bottom: 12%;
    }

    @include xl() {
      bottom: -8%;
    }
    .ourServices-title {
      font-size: 10rem;
      margin: 0;
      letter-spacing: 1.1875rem;
      text-transform: uppercase;
      color: #fff;
      white-space: pre;

      @include phone() {
        font-size: 4.375rem;
        line-height: 5rem;
        white-space: pre-wrap;
        margin-left: 0;
        text-align: center;
        width: 100%;
        letter-spacing: 0.625rem;
        font-weight: 900;

        @include galaxyFold() {
          font-size: 2rem;
        }
      }

      @include tablet() {
        font-size: 5.1875rem;
        white-space: pre;
      }

      @include mdTablet() {
        font-size: 5.4rem;
        white-space: pre;
      }

      @include xTablet() {
        font-size: 7.7rem;
      }

      @include xl() {
        font-size: 14.1875rem;
      }
    }
  }

  .links-list {
    width: 48%;
    background: rgb(0, 230, 229);
    background: linear-gradient(
      180deg,
      rgba(0, 230, 229, 0.56) 0%,
      rgba(0, 123, 248, 0.1) 50%,
      rgba(0, 123, 248, 0) 100%
    );
    z-index: 2;
    position: relative;
    margin-left: 26%;
    margin-top: -5rem;
    border-radius: 2rem;
    height: 100%;
    padding: 0 0.5rem 0.75rem;

    @include phone() {
      width: 90%;
      margin-left: 5%;
      padding: 0 5% 0.875rem;
      border-radius: 0.9375rem;
    }

    @include tablet() {
      width: 86%;
      margin-left: 7%;
      padding: 0 7% 0.75rem;
    }

    .arrow-container {
      height: 5rem;

      @include xl() {
        height: 6rem;
      }

      button {
        background: transparent;
        color: white;
        width: 2.8125rem;
        height: 2.8125rem;
        border-radius: 50%;
        transition: all 0.4s ease;

        svg {
          width: 1.25rem;
          height: 1.25rem;
        }

        &:hover {
          background: rgba(68, 68, 68, 0.1);
        }
      }
    }

    .menu-list {
      display: grid;
      margin-top: 12%;
      padding: 0 18%;
      @include phone() {
        margin-top: 4%;
      }

      @include tablet() {
        margin-top: 12%;
      }

      @include xl() {
        margin-top: 14%;
        padding: 0 12% 0 17%;
      }

      a {
        width: fit-content;
        margin-top: 5.6%;
        font-size: 1.8125rem;
        font-style: italic;
        text-decoration: none;
        color: white;
        text-transform: capitalize;
        font-weight: 300;
        align-self: center;
        display: inline-block;
        line-height: 50px;
        text-shadow:
          0 2px 4px rgba(0, 119, 119, 0.25),
          0px -2px 4px rgba(255, 255, 255, 0.5);

        @include phone() {
          font-size: 1.425rem;
          margin-top: 8.5%;

          @include galaxyFold() {
            font-size: 1rem;
          }
        }

        @include tablet() {
          font-size: 2.5rem;
          margin-top: 6.1%;
        }

        div {
          gap: 1.4rem;
          @include phone() {
            gap: 0.6875rem;
          }
        }

        @include xl() {
          font-size: 2.5rem;
        }
      }
    }
  }
}
