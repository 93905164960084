.overlay {
  transition:
    transform 0.3s ease,
    opacity 0.3s ease;
  transform: scale(1);
  opacity: 1;
  cursor: pointer;
}

.overlay.playing {
  transform: scale(1.5);
  opacity: 0;
}
