@import "src/shared/styles/mixin";
@import "src/shared/styles/animation";

.webSolutions-container {
  animation: fade-in-up 0.4s;

  .main-section-label {
    font-size: 129px !important;
    line-height: 164px !important;
    margin-left: -2.1875rem !important;
    font-weight: 900 !important;

    @include tablet() {
      font-size: 8.6vw !important;
      line-height: 159px !important;
      margin-left: 0 !important;
      text-align: center;
      padding-top: 32% !important;
    }

    @include xTablet() {
      padding-top: 36% !important;
    }

    @include xl() {
      font-size: 159px !important;
      padding-top: 12% !important;
      line-height: 180px !important;
    }

    @include phone() {
      font-size: 9vw !important;
      line-height: 26vw !important;
      padding-top: 40% !important;
      text-align: center;
      margin-left: 0 !important;
      white-space: pre;
      letter-spacing: 10px !important;
      font-weight: 900 !important;
      margin-top: 5% !important;

      @include xs() {
        font-size: 8.5vw !important;
      }
    }
  }

  .main-section-description {
    font-size: 24px !important;
    width: 83% !important;
    letter-spacing: 3.1px;

    @include tablet() {
      font-size: 4.6vw !important;
      width: 88.5% !important;
      letter-spacing: 4.1px !important;
    }

    @include phone() {
      font-size: 3.4vw !important;
      text-align: center !important;
      width: 100% !important;
    }

    @include xl() {
      font-size: 30px !important;
      width: 83% !important;
    }
  }

  .main-section-image {
    padding-top: 0 !important;
    width: 80% !important;

    @include tablet() {
      padding-top: 57px !important;
      width: 99% !important;
    }

    @include phone() {
      padding-top: 7% !important;
      width: 160% !important;
    }
  }

  .custom-robot-background {
    margin-top: -130vh;
    z-index: 2;

    @include phone() {
      margin-top: -111vh;
    }
    .custom-overlay {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 1%,
        rgb(34, 37, 41, 0) 8%,
        rgba(0, 144, 250, 0) 20%,
        rgba(0, 144, 250, 0) 70%,
        rgb(35, 37, 41) 88%,
        rgb(34, 37, 41) 100%
      );
    }
  }
}
