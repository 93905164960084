@import "src/shared/styles/mixin";

.developmentHub-container {
  display: grid;
  padding-bottom: 40%;

  .phone-image {
    width: 100vw;
    z-index: 1;
    position: relative;
    object-fit: cover;
    margin-top: 8%;
  }

  .padding {
    padding: 14% 8% 90vh;

    @include tablet() {
      padding: 8% 13% 100vh;
    }

    @include phone() {
      padding: 12% 11% 60vh 13%;
    }

    @include xl() {
      padding-top: 8%;
    }

    .hubItem-container {
      @include desktop() {
        &:nth-child(3) {
          margin-bottom: 14% !important;
        }

        &:nth-child(4) {
          margin-bottom: 14% !important;
        }

        &:nth-child(6) {
          margin-bottom: 12% !important;
        }

        &:nth-child(7) {
          margin-bottom: 8% !important;
        }
      }

      &.right {
        justify-self: end;
        text-align: right;
        margin-bottom: 14%;

        @include phone() {
          margin-bottom: 44%;
        }

        p {
          margin-left: 21%;
          margin-bottom: 3%;

          @include tablet() {
            margin-left: 1%;
            margin-bottom: 9%;
          }

          @include phone() {
            margin-left: 1%;
            margin-bottom: 9%;
          }
        }
      }

      &.left {
        justify-self: start;
        margin-bottom: 18%;

        @include phone() {
          margin-bottom: 44%;
        }
      }

      h2 {
        font-size: 90px;
        text-transform: uppercase;
        white-space: pre-wrap;
        line-height: 106px;
        margin-top: 0;
        letter-spacing: 9px;
        margin-bottom: 2%;

        @include tablet() {
          font-size: 6.2vw;
          line-height: 7vw;
        }

        @include phone() {
          font-size: 7.2vw;
          line-height: 9vw;
          font-weight: bold;
          letter-spacing: 2px;
        }

        @include xl() {
          font-size: 113px;
          line-height: 123px;
        }
      }

      p {
        width: 79%;
        font-size: 20px;
        letter-spacing: 2.3px;
        margin-top: 0;

        @include tablet() {
          width: 100%;
          margin-top: 7%;
          font-size: 4vw;
        }

        @include phone() {
          width: 100%;
          margin-top: 10%;
          font-size: 4vw;
          line-height: 5.3vw;
        }

        @include xl() {
          font-size: 25px;
          letter-spacing: 2.4px;
        }
      }

      .letsTalk {
        font-size: 28px;
        font-weight: 300;
        margin-left: 0.4%;
        margin-top: 2%;

        @include tablet() {
          font-size: 5.4vw;
        }

        @include phone() {
          font-size: 5.7vw;
          margin-top: 10%;
          line-height: 8.3vw;
        }

        @include xl() {
          font-size: 35px;
        }

        a {
          text-decoration: none;
          color: var(--secondary);
        }
      }
    }

    .note {
      color: var(--color);
      position: relative;
      margin-bottom: 7%;
      width: 70%;
      margin-left: 15%;
      font-size: 22px;
      font-weight: 300;

      @include tablet() {
        margin-bottom: 11%;
        width: 97%;
        margin-left: 2%;
        font-size: 4vw;
      }

      @include phone() {
        margin-bottom: 11%;
        width: 95%;
        margin-left: 2%;
        font-size: 4vw;
        line-height: 6vw;
      }

      @include xl() {
        font-size: 27px;
      }
    }
  }
}
