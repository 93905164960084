@import "src/shared/styles/mixin";
@import "src/shared/styles/animation";

.insightDetails-container {
  background-color: white;

  .insight-content {
    z-index: 6;
    padding: 10% 25% 90%;
    color: var(--dark-text);
    animation: fade-in-up 0.4s;

    @include phone() {
      padding: 42% 0 100%;
    }
  }

  .custom-robotBackground {
    margin-top: -44vh;

    img {
      z-index: 0;
      background: var(--image-background);
    }

    .light-overlay {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 1%,
        rgb(255, 255, 255) 8%,
        rgba(0, 144, 250, 0) 20%,
        rgba(0, 144, 250, 0) 70%,
        rgb(35, 37, 41) 88%,
        rgb(34, 37, 41) 100%
      );
    }

    .contact-container {
      padding-top: 125vh;
    }
  }
}
