@import "src/shared/styles/mixin";
@import "src/shared/styles/animation";

.welcome-container {
  background: radial-gradient(
    111.62% 232.73% at 103.19% 103.88%,
    #00ffe0 0%,
    #0057ff 100%
  );
  min-height: 58vh;

  .text-content {
    width: 50%;
    padding-top: 20vh;
    z-index: 2;
    padding-bottom: 4%;
    animation: fade-in-up 0.4s;

    &.various-container {
      width: 42%;
    }

    @include mdDesktop() {
      width: 45%;
    }

    @include phone() {
      padding-top: 30vh;
      width: 100% !important;
    }

    @include tablet() {
      padding-top: 30vh;
      width: 100% !important;
    }

    p {
      font-weight: 300;
      font-size: 26px;
      letter-spacing: 1.2px;

      @include phone() {
        font-size: 20px;
      }

      @include tablet() {
        font-size: 22px;
      }

      @include xl() {
        font-size: 32px;
      }
    }

    .title {
      font-size: 60px;
      color: var(--color);
      font-weight: 500;
      text-align: left;
      margin-bottom: 0;
      margin-top: 0;

      @include xl() {
        font-size: 84px;
      }

      @include phone() {
        font-size: 45px;
      }
    }

    .tagline {
      padding-bottom: 4%;
      width: 60%;

      @include phone() {
        width: 100%;
      }
    }
  }

  .cover-image {
    width: 50%;
    position: absolute;
    top: 28%;
    right: 0;

    &.branding-container {
      right: 5%;
    }

    &.multiple {
      top: 32%;

      @include lg() {
        bottom: -38%;
      }
    }

    @include mdDesktop() {
      top: 20%;
      width: 55%;
    }

    @include xl() {
      top: 24%;
    }

    @include tablet() {
      position: unset;
      width: 100%;
    }

    @include phone() {
      position: unset;
      width: 100%;
    }
  }

  .mobile-container {
    width: 30%;

    @include tablet() {
      width: 70%;
      margin-left: 25%;
      padding-bottom: 8vh;
    }

    @include phone() {
      width: 70%;
      margin-left: 25%;
      padding-bottom: 8vh;
    }
  }
}
