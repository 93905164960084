body {
  --primary: #0057ff;
  --accent: #555555;
  --secondary: #00ffe0;
  --color: #fff;
  --background: #fff;
  --contact-background: #222529;
  --dark-text: #000;
  --gray-text: #666;
  --case-study-section: #999;
  --case-study-gray-background: #f6f6f6;
  --light-gray-text: #444444;
  --image-background: rgba(204, 204, 204, 0.2);
  --disabled: rgba(255, 255, 255, 0.6);
}
