@import "src/shared/styles/mixin";
@import "src/shared/styles/animation";

.branding-container {
  animation: fade-in-up 0.4s;

  .custom-bottom-container {
    @include desktop() {
      bottom: 1%;
    }
  }
  @include tablet() {
    padding-bottom: 1%;
  }
  .custom-label {
    margin-left: 11% !important;

    @include tablet() {
      font-size: 13vw !important;
      margin-left: 0 !important;
      line-height: 184px !important;
      padding-top: 32% !important;
      text-align: center !important;

      @include xTablet() {
        padding-top: 36% !important;
      }
    }

    @include phone() {
      font-size: 13vw !important;
      margin-left: 0 !important;
      width: 100% !important;
      text-align: center !important;
      line-height: 128px !important;
      padding-top: 44% !important;
      letter-spacing: 7px !important;
    }
  }

  .custom-description {
    width: 83% !important;

    @include tablet() {
      width: 100% !important;
      font-size: 3.7vw !important;
      text-align: center !important;
    }

    @include phone() {
      font-size: 3.5vw !important;
      width: 100% !important;
      text-align: center !important;
    }
  }

  .custom-image {
    padding-top: 0 !important;
    width: 100% !important;
    position: relative;
    top: -18vh;

    @include tablet() {
      height: 35vh;
      top: 1vh;
      right: -3%;
      transform: scale(1.9);
    }

    @include phone() {
      padding-top: 0 !important;
      width: 100% !important;
      position: relative;
      top: 0;
      margin-top: 22%;
      transform: scale(2) rotate(350deg);
    }
  }

  .border-image {
    position: absolute;
    width: 76%;
    left: 12%;
    top: 5.7%;
    backdrop-filter: blur(0.6px);

    @include xl() {
      top: -1%;
    }

    @include tablet() {
      width: 100%;
      left: 0;
      top: 7%;
    }
  }

  .bottom-section {
    @include tablet() {
      margin-top: -120%;
    }
  }
}
